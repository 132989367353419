/*!

Silimuse beta

Filippo Carnovalini

*/
import React, { Component } from "react";

import Card from "components/Card/Card";
import { I18n } from 'aws-amplify';

import { Container } from "react-bootstrap";

import upload from "assets/img/icons/upload.svg"
import mix from "assets/img/icons/mix.svg"
import match from "assets/img/icons/match.svg"
import archive from "assets/img/icons/archive.png"
import prova from "assets/img/icons/home.png"



class About extends Component {
  constructor(props) {
      super(props)

      let oldvalues = {};
      if (this.props.value){
        this.props.value.forEach((item, i) => {
          oldvalues[i] = item
        });
      }
      this.state = {
          selected: null,
          isPlaying: false,
          values: oldvalues,
          piece: null,
          zoom: 50
      }

  }




  render() {
    return (
      <div className="content">
        <Container fluid>

          <h2><span className="numberDot active"><img src={prova} width="50px"/></span> {I18n.get("How does it work?")}
          </h2>


          <Card
            content={<>
              <h3>Quick Manual</h3>
              <h4><img alt="" className="icon" src={upload} width="40px"/>Step 1: Select the tracks</h4>
              <ul>
                <li>Select from your computer the files for the tracks (Voice, Choir, Guitar, …) you recorded.</li>
              </ul>
              <h4><img alt="" className="icon" src={match} width="40px"/>Step 2: Prepare the tracks</h4>
              <ul>
                <li>If the tracks have different length, you might want to trim and/or move some tracks to make sure they are aligned.</li>
                <li>
                  Use the visual tool to trim and move tracks. You can click and drag the pink cursors to trim the track, and click and drag the gray regions to move the tracks. Zoom in and out to view more precisely where to move them.</li>
                <li>You can mute each track singularly with the mute (M) button, and listen to just one track clicking the solo (S) button. You cannot remove tracks, but if you leave a track muted with (M) that one will not be considered.</li>
              </ul>
              <h4><img alt="" className="icon" src={mix} width="40px"/>Step 3: Specify the instruments</h4>
              <ul>
                  <li>Specify the instrument that is recorded in each track. If more than one instrument is recorded in a single track, or the instrument is not present in our list, choose the most fitting option.</li>
              </ul>
              <h4><img alt="" className="icon" src={archive} width="40px"/>Step 4: Automated Mixing</h4>
              <ul>
                  <li>Click the upload button: you are done!</li>
                  <li>Our system will queue your project and process it as soon as possible. It might take up to ten minutes to get the mix back from the server, once the processing has started.</li>
                  <li>
                    Our system is still a beta: let us know if you encounter any problem or if you have any suggestion!
                  </li>
              </ul>

              </>}
              />


        </Container>
      </div>
    );
  }
}

export default About;
