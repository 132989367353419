import React, { Component } from "react";
import { Container, Row, Form, Button, Col, Alert } from "react-bootstrap";
import { Card } from "components/Card/Card";

import { Auth, I18n } from 'aws-amplify';

export class SignIn extends Component {
  constructor(props) {
    super(props)
    this._validAuthStates = ["signIn"]
    this.state = {
      validated: false,
      email: undefined,
      password: undefined,
      authError: "",
      showAuthError: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.reset = this.reset.bind(this);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  reset() {
    this.setState({
      validated: false,
      email: undefined,
      password: undefined,
      authError: "",
      showAuthError: false
    })
  }

  async SignIn() {
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      this.reset();
      this.props.onStateChange("signedIn", user);
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        this.props.onStateChange("confirmSignUp", { "email": this.state.email, "password": this.state.password });
      }
      this.setState(
        {
          showAuthError: true,
          validated: false,
          authError: error.message
        });
        console.log('error signing in', error);
      }
    };

    handleSubmit(event) {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        this.SignIn();
      }
      this.setState({ validated: true });
    };

    render() {
      if (!this._validAuthStates.includes(this.props.authState)) {
        return null;
      }
      return (
        <Container className="mt-5">
          <Row >
            <Col lg="3" />
            <Col>
              <Card title={I18n.get("Log In to Silimuse")}
                content={
                  <div>
                    <Row>
                      <Form.Group as={Col} xs="12" >
                        <Button className="btn-social" onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })} block><i className="fa fa-facebook-official fa-3x"></i>
                        <span className="pl-5">{I18n.get("Log In with Facebook")}</span></Button>
                      </Form.Group>
                      <Form.Group as={Col} xs="12" >
                        <Button className="btn-social" onClick={() => Auth.federatedSignIn({ provider: 'Google' })} block>
                          <i className="fa fa-google fa-3x"></i><span className="pl-5">{I18n.get("Log In with Google")}</span>
                        </Button>
                      </Form.Group>
                    </Row>
                    <p className="middleLine">{I18n.get("or")}</p>
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                      <Form.Group controlId="validationCustom02">
                        <Form.Label>{I18n.get("Email Address")}</Form.Label>

                        <Form.Control
                          required
                          name="email"
                          type="email"
                          placeholder="you@yourmail.com"
                          value={this.state.email}
                          onChange={this.onChange}
                          />
                        <Form.Control.Feedback type="invalid">
                          {I18n.get("Please insert a valid email address.")}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>{I18n.get("Looks good!")}</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustomUsername">
                        <Form.Label>Password</Form.Label>

                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="********"
                          required
                          value={this.state.password}
                          onChange={this.onChange}
                          />
                        <Form.Control.Feedback type="invalid">
                          {I18n.get("Please choose a username.")}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Alert variant="danger" show={this.state.showAuthError}>
                          {this.state.authError}
                        </Alert>
                        <Alert variant="danger" show={this.state.showAuthError} onClick={() => { this.reset(); this.props.onStateChange("forgotPassword") }}>
                          {I18n.get("Forgot password? Click HERE")}
                        </Alert>
                      </Form.Group>

                      <Row>
                        <Col xs="8">
                          <p>{I18n.get("Don't have an account?")}<br /><a href="#signup" onClick={() => { this.reset(); this.props.onStateChange("signUp") }}>{I18n.get("Register")}</a></p>
                        </Col>
                        <Col xs="4">
                          <Button className="btn-round" type="submit">{I18n.get("Sign In")}</Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                }
                />
              {/* END CARD */}
            </Col>
            <Col lg="3" />
          </Row>
        </Container >
      );
    }
  }

  export default SignIn;
