/* tslint:disable */
/* eslint-disable */

export const SILjoinGroup = /* GraphQL */ `
  mutation SILjoinGroup($input: JoinGroupInput!) {
    joinGroup(input: $input)
  }
`;

export const OLDcreateJob = /* GraphQL */ `
  mutation SILCreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      createDate
      price
    }
  }
`;

export const assignJob = /* GraphQL */ `
  mutation SILAssignJob($id: ID!) {
    assignJob(id: $id) {
      id
    }
  }
`;
export const cancelJob = /* GraphQL */ `
  mutation SILCancelJob($id: ID!) {
    cancelJob(id: $id)
  }
`;
export const voteEngineerForJob = /* GraphQL */ `
  mutation SILVoteEngineerForJob($vote: Float!, $id: ID!) {
    voteEngineerForJob(vote: $vote, id: $id) {
      id
    }
  }
`;
export const submitJobReview = /* GraphQL */ `
  mutation SILSubmitJobReview($input: SubmitJobReviewInput!, $id: ID!) {
    submitJobReview(input: $input, id: $id) {
      id
    }
  }
`;
export const rejectEngineer = /* GraphQL */ `
  mutation SILRejectEngineer($id: ID!) {
    rejectEngineer(id: $id) {
      id
      status
    }
  }
`;

export const reportEngineer = /* GraphQL */ `
  mutation SILReportEngineer($id: ID!) {
    reportEngineer(id: $id){
      id
      status
    }
  }
`;

export const acceptJobReview = /* GraphQL */ `
  mutation SILacceptJobReview($id: ID!) {
    acceptJobReview(id: $id) {
      id
      finalMix{
        url
      }
      status
    }
  }
`;

export const requestJobReview = /* GraphQL */ `
  mutation SILrequestJobReview($input: RequestJobReviewInput!, $id: ID!) {
    requestJobReview(input: $input, id: $id) {
      id
      status
    }
  }
`;

export const payJobWithPromoCode = /* GraphQL */ `
  mutation SILPayJobWithPromoCode($id: ID!, $code: String!) {
    payJobWithPromoCode(id: $id, code: $code) {
      id
    }
  }
`;

export const createPayOrderForJob = /* GraphQL */ `
  mutation SILCreatePayOrderForJob($id: ID!) {
    createPayOrderForJob(id: $id)
  }
`;
export const capturePayOrderForJob = /* GraphQL */ `
  mutation SILCapturePayOrderForJob($id: ID!) {
    capturePayOrderForJob(id: $id) {
      id
      price
    }
  }
`;

export const updateEngineerData = /* GraphQL */ `
  mutation SILUpdateEngineerData($input: EngineerDataInput!) {
    updateEngineerData(input: $input) {
      username
      userData {
        acceptedTermsVersion
        vatType
        companyName
        name
        surname
        address
        city
        postCode
        country
        vatNumber
        provincia
        codiceDestinatario
        PEC
      }
    }
  }
`;
export const updateMusicianData = /* GraphQL */ `
  mutation SILUpdateMusicianData($input: MusicianDataInput!) {
    updateMusicianData(input: $input) {
      username
      userData {
        acceptedTermsVersion
        vatType
        companyName
        name
        surname
        address
        city
        postCode
        country
        vatNumber
        provincia
        codiceDestinatario
        PEC
      }
    }
  }
`;


export const createJob = /* GraphQL */ `
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      status
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      id
      createDate
      endDate
      title
      tracklist {
        number
        instrument
      }
      finalMix {
        url
        bucket
        region
        key
      }
      status
      createdAt
      updatedAt
    }
  }
`;
