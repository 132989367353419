/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";
import { I18n } from 'aws-amplify'
import { genres, listFetch } from "variables/SiliVariables";

import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import {vatprice} from "variables/SiliVariables";

export class JobClosed extends Component {
    render() {
        let date = new Date(this.props.job.endDate);
        let month = date.getMonth();
        let day = date.getDate();
        return (
            <div className={"card "+(this.props.job.status !== "END" ? "failedJob": "archivedJob")}>
                <div className="header">
                    <h4 className="title">{listFetch(genres, this.props.job.genre)} {I18n.get("Song")}  - <i className="fa fa-eur" />
                    {this.props.job.status === "END" ?
                        vatprice(this.props.job.prize,"EUR_VAT") :
                        (0.00).toFixed(2)
                    }
                </h4>
                </div>
                <div className="content">
                    <p>
                        {I18n.get("Number of tracks:")} {this.props.job.tracklist.length}
                    </p>
                    <p>
                        {I18n.get("Job finished on date:")} {day}/{month + 1}
                        <br />
                    </p>

                {this.props.job.vote !== null ? <>
                  <div><span>Vote: </span><Rater total={5} rating={this.props.job.vote} interactive={false} /></div>
                </> : <p>This mix did not receive any evaluation.</p>}
                {this.props.job.status !== "END" &&
                    <h4>This job was not paid: </h4>
                }
                {this.props.job.status === "END_FAILED" &&
                    <p>You did not submit a mix before the expiration time.</p>
                }
                {this.props.job.status === "END_REJECTED" &&
                    <p>Your client did not like your mix and requested another engineer to work on his song.</p>
                }
                {this.props.job.status === "END_REPORTED" &&
                    <p>Your client reported your mix as abusing the terms of service of this platform. Should this happen again, your account might be suspended.</p>
                }


                </div>
            </div>
        );
    }
}

export default JobClosed;
