/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";

import { Form, Popover, OverlayTrigger  } from "react-bootstrap";
import SelectGrid from './SelectGrid'

import SelectAudioFile from './SelectAudioFile'
import { instruments, adjectives } from "variables/SiliVariables";
import { I18n } from 'aws-amplify';

function popover(title,element) {
  return (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>
        {element}
      </Popover.Content>
    </Popover>
  );
}

export class TrackInstrument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionClass: ""
    }
  }

  // <Form.Label>{I18n.get("Audio track:")} &nbsp;
  //   <OverlayTrigger  placement="top" overlay={popover(I18n.get("Audio Track"),I18n.get("Add an audio file to the project."))}>
  //   <span className="fa fa-question-circle fa-1x" title="info" ></span>
  //   </OverlayTrigger></Form.Label>
  // <SelectAudioFile track={i} onUpdateTrack={(e) => this.props.onUpdateTrack(e, i)} />
  // <p className="formError">{tr.errors.fname}</p>

  render() {
    //let tr = this.props.track;
    let i = this.props.number;
    return (
      <div>
      <Form.Group >



      </Form.Group>
      <Form.Group>
        <Form.Label>{I18n.get("Instrument:")} &nbsp;
          <OverlayTrigger  placement="top" overlay={popover(I18n.get("Instrument"),I18n.get("Specify the instrument recorded on the track. If the instrument is not in the list or there are multiple instrument recorded on this track select the most similar option."))}>
          <span className="fa fa-question-circle fa-1x" title="info" ></span>
          </OverlayTrigger></Form.Label>
        <SelectGrid
          styles={{
            menu: (provided, state) => ({
              ...provided,
              "z-index": 10
            })
          }}
          name="instrument"
          placeholder={I18n.get("Select...")}
          onChange={(selection) => this.props.onUpdateTrack({ target: { name: "instrument", value: selection.value } }, i)}
          options={instruments} />
        {/*<p className="formError">{tr.errors.instrument}</p>*/}
        </Form.Group>
        {/*
        <Form.Group>
        <Form.Label>{I18n.get("Describe your sound:")} &nbsp;
          <OverlayTrigger  placement="top" overlay={popover(I18n.get("Sound Adjective"),I18n.get("Select an adjective to describe the desired sound behaviour."))}>
          <span className="fa fa-question-circle fa-1x" title="info" ></span>
          </OverlayTrigger></Form.Label>

          <SelectGrid
          className={this.state.descriptionClass}
          styles={{
            menu: (provided, state) => ({
              ...provided,
              "z-index": 10
            })
          }}
          name="instrument"
          placeholder={I18n.get("Select...")}
          onChange={(selection) => this.props.onUpdateTrack({ target: { name: "adjective", value: selection.value } }, i)}
          options={adjectives} />
      <p className="formError">{tr.errors.adjective}</p>
      </Form.Group>
      */}
    </div>
    );
  }
}

export default TrackInstrument;
