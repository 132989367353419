/* tslint:disable */
/* eslint-disable */


export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      status
      createDate
      endDate
      finalMix {
        url
      }
      createdAt
      updatedAt
    }
  }
`;

export const getSpotifyAuthToken = /* GraphQL */ `
  query SILGetSpotifyAuthToken {
    getSpotifyAuthToken
  }
`;

export const SILlistJobs = /* GraphQL */ `
 query SILlistJobs {
   jobsByUnassigned {
     items {
       id
       prize
       createDate
       genre
       availableTime
       extras
       tracklist{
         instrument
       }
       references{
         url
         referenceType
       }
     }
     nextToken
   }
 }
`;

export const SILactiveJobsMus = /* GraphQL */ `
 query SILactiveJobsMus(
   $musician: String!
 ) {
   jobsByMusicianJobProgression(
     sortDirection: DESC,
     musician: $musician,
     progressionLastUpdate: {beginsWith: {progression:ACTIVE}},
     filter: {status: {ne:CANCELED}}
   ) {
     items {
       id
       title
       musician
       engineer
       createDate
       assignedDate
       availableSkips
       isCancelable
       status
       price
       vote
       genre
       availableReviews
       reviews {
         file {
           url
         }
         status
         submitDate
         answerDate
         masterParams {
           brightness
           hardness
           reference
         }
         tracksParams {
           number
           adjective
           effect
           volume
           waveformParams {
             adjective
             effect
             volume
             start
             end
           }
         }

       }
       tracklist{
         number
         instrument
         adjectives
       }
     }
   }
 }
`;
export const SILpastJobsMus = /* GraphQL */ `
 query SILpstJobsMus(
   $musician: String!
 ) {
   jobsByMusician(
     sortDirection: DESC,
     musician: $musician,
     statusLastUpdate: {beginsWith: {status:END}}
   ) {
     items {
       title
       id
       musician
       engineer
       createDate
       assignedDate
       status
       price
       genre
       tracklist {
         instrument
       }
       finalMix{
         url
       }
     }
   }
 }
`;
export const SILopenJobsList = /* GraphQL */ `
 query oJL(
   $engineer: String!
 ) {
   jobsByEngineerJobProgression(
     sortDirection: DESC,
     progressionLastUpdate: {beginsWith: {progression: ACTIVE}},
     engineer: $engineer
   ) {
     items {
       id
       status
       createDate
	   timeoutDate
       prize
       genre
       references{
         url
         referenceType
       }
       extras
       tracksZipFile {
         url
       }
       reviews {
         file {
           url
         }
         status
         submitDate
         answerDate
         masterParams {
           brightness
           hardness
           reference
         }
         tracksParams {
           number
           adjective
           effect
           volume
           waveformParams {
             adjective
             effect
             volume
             start
             end
           }
         }

       }
       tracklist{
         number
         instrument
         adjectives
       }
     }
     nextToken
   }
 }
`;
export const closedJobsEng = /* GraphQL */ `
  query SILcJE(
    $engineer: String
  ) {
    jobsByEngineerJobProgression(
      sortDirection: DESC,
      progressionLastUpdate: {beginsWith: {progression: END}},
      engineer: $engineer
    ) {
      items {
        id
        status
        createDate
        paidDate
        assignedDate
        timeoutDate
        endDate
        genre
        references {
          url
          referenceType
        }
        extras
        tier
        tracklist {
          number
        }
        vote
        prize
      }
      nextToken
    }
  }
`;

export const engineerCanAssign = /* GraphQL */ `
  query SILeCA($username: String!) {
    getEngineerData(username: $username) {
      jobAssignLockedUntil
      reports
      status
      userData {
        vatType
      }
    }
  }
`;

export const codesByUser = /* GraphQL */ `
  query SILCodesByUser(
    $username: String
  ) {
    codesByUser(
      username: $username
    ) {
      items {
        code
        usedDate
        tier
        extras
      }
      nextToken
    }
  }
`;


export const getEngineerUserData = /* GraphQL */ `
  query SILGetEngineerUD($username: String!) {
    getEngineerData(username: $username) {
      userData {
        acceptedTermsVersion
        vatType
        companyName
        name
        surname
        address
        city
        postCode
        country
        vatNumber
        provincia
        codiceDestinatario
        PEC
      }
      paypalMail
      paypalPhone
      paypalId
    }
  }
`;
export const getMusicianUserData = /* GraphQL */ `
  query SILGetMusicianUD($username: String!) {
    getMusicianData(username: $username) {
      userData {
        acceptedTermsVersion
        vatType
        companyName
        name
        surname
        address
        city
        postCode
        country
        vatNumber
        provincia
        codiceDestinatario
        PEC
      }
      promoCodes {
        items {
          code
          usedDate
          tier
          extras
          username
        }
        nextToken
      }
    }
  }
`;
