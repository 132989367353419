/*!

Silimuse beta

Filippo Carnovalini

*/
import AvailableJobs from "views/AvailableJobs.jsx";
import OpenJobs from "views/OpenJobs.jsx";
import ClosedJobs from "views/ClosedJobs.jsx";

const engRoutes = [
  {
    path: "/available",
    name: "Available Jobs",
    icon: "pe-7s-musiclist",
    component: AvailableJobs,
    layout: "/eng"
  },
  {
    path: "/open",
    name: "Open Job",
    icon: "pe-7s-display1",
    component: OpenJobs,
    layout: "/eng"
  },
  {
    path: "/closed",
    name: "Closed Jobs",
    icon: "pe-7s-album",
    component: ClosedJobs,
    layout: "/eng"
  }
];

export default engRoutes;
