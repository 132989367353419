import { I18n } from 'aws-amplify';
import React from "react";
import { Form, Popover, OverlayTrigger} from "react-bootstrap";
import TrackReview from "./TrackReview";

/*
 * A component that shows review input form based on given job
 *
 * props.onReviewParamsChanged - called when parameters changes.
 * props.readOnly - prevent changes to review forms
 *
 * */

 function popover(title,element) {
   return (
     <Popover id="popover-basic">
       <Popover.Title as="h3">{title}</Popover.Title>
       <Popover.Content>
         {element}
       </Popover.Content>
     </Popover>
   );
 }

class Review extends React.Component {
    constructor(props) {
        super(props);

        let review = (props.job.reviews && props.job.reviews.length > 0) ? props.job.reviews.slice(-1)[0] : null;
        let masterParams = review ? review.masterParams : null;
        this.state = {

            master: {
                brightness: masterParams ? masterParams.brightness : 0.5,
                hardness: masterParams ? masterParams.hardness : 0.5,
                reference: masterParams ? masterParams.reference : 0.5
            },

            tracks: review ? review.tracksParams : null,
            waveform: null
        };

        this.updateReviewData = this.updateReviewData.bind(this);
    }




    updateReviewData(val) {
        this.setState(val,
            () => this.props.onDataUpdate({ masterParams: this.state.master, tracksParams: this.state.tracks })
        );
    }


    render() {
        let reviewLevel = 3 /*TODO:EDO this.props.job.tier based*/;

        //Master review
        let level1 = <>
            <h4>{I18n.get("1. Global Mix Review (Mastering)")}</h4>

            <Form>
                <div className="slidersReview">
                        <p>{I18n.get("Do you want your song to sound darker or brighter?")} &nbsp; <OverlayTrigger  placement="top" overlay={popover(I18n.get("Brightness"),I18n.get("Specify if you want your final mix to sound brighter or darker. ATTENTION: this parameter will affect the entire mix. If you only want to change the behaviour of one track, you can specify it in the Advanced Review Section."))}>

                        <span className="fa fa-question-circle fa-1x" title="info" ></span>
                        </OverlayTrigger></p>
                        <Form.Group controlId="masterBrightnessRange">
                            <Form.Label className="pull-left">{I18n.get("Dark")}</Form.Label>
                            <Form.Label className="pull-right">{I18n.get("Bright")}</Form.Label>
                            <Form.Control
                                disabled={this.props.readOnly}
                                type="range"
                                value={this.state.master.brightness * 100}
                                onChange={(event) => this.updateReviewData({ master: { ...this.state.master, brightness: event.target.value / 100 } })} />
                        </Form.Group>

                        <p>{I18n.get("Do you want your song to sound softer or harder?")} &nbsp; <OverlayTrigger  placement="top" overlay={popover(I18n.get("Hardness"),I18n.get("Specify if you want your final mix to sound Harder/Louder or Softer/Less Compressed. ATTENTION: this parameter will affect the entire mix. If you only want to change the behaviour of one track, you can specify it in the Advanced Review Section."))}>
                          <span className="fa fa-question-circle fa-1x" title="info" ></span>
                          </OverlayTrigger>
                        </p>
                        <Form.Group controlId="masterHardnessRange">
                            <Form.Label className="pull-left">{I18n.get("Soft")}</Form.Label>
                            <Form.Label className="pull-right">{I18n.get("Hard")}</Form.Label>
                            <Form.Control
                                disabled={this.props.readOnly}
                                type="range"
                                value={this.state.master.hardness * 100}
                                onChange={(event) => this.updateReviewData({ master: { ...this.state.master, hardness: event.target.value / 100 } })} />

                        </Form.Group>

                        <p>{I18n.get("Do you want to give more freedom to the engineer to be inspired by creativity or to stay more faithful to the reference songs?")} &nbsp; <OverlayTrigger  placement="top" overlay={popover(I18n.get("Creativity"),I18n.get("Specify if you want the mix to be more faithful to the reference tracks or if you'd like to leave to the sound engineer more production decisions."))}>
                          <span className="fa fa-question-circle fa-1x" title="info" ></span>
                        </OverlayTrigger>
                        </p>
                        <Form.Group controlId="masterReferenceRange">
                            <Form.Label className="pull-left">{I18n.get("Engineer Creativity")}</Form.Label>
                            <Form.Label className="pull-right">{I18n.get("Reference Songs")}</Form.Label>
                            <Form.Control
                                disabled={this.props.readOnly}
                                type="range"
                                value={this.state.master.reference * 100}
                                onChange={(event) => this.updateReviewData({ master: { ...this.state.master, reference: event.target.value / 100 } })} />

                        </Form.Group>

                </div>
            </Form>
        </>;

        //Tracks / waveform level review
        let level23 = <>
            <hr />
            <h4>{I18n.get("2. Single Tracks Mix Review (Mixing)")}</h4>

            <p>Now tell us something about each track you uploaded:</p>

            <TrackReview
                value={this.state.tracks}
                job={this.props.job}
                onDataUpdate={(val) => this.updateReviewData({ tracks: val })}
                readOnly={this.props.readOnly}
                hasWaveform={reviewLevel >= 3}
            />
        </>;


        return (
            <>
            <p>{I18n.get("Tip: use these features carefully. The engineer may have already done the best choices for your mix!")}</p>
                {reviewLevel >= 1 && level1}
                {reviewLevel >= 2 && level23}
            </>
        );
    }
}

export default Review;
