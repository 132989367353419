/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";
import { Container } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import JobClosed from "components/Card/JobClosed.jsx"

import { graphqlOperation, I18n } from 'aws-amplify';
import { Connect } from "aws-amplify-react";
import * as queries from 'graphql/SILqueries';
import { Spinner } from "react-bootstrap";

import {errorFetch} from "variables/SiliErrors";


class ClosedJobs extends Component {
    render() {
        let content = <Connect query={graphqlOperation(queries.closedJobsEng, { engineer: this.props.userID })}>
            {({ data: { jobsByEngineerJobProgression }, loading, errors }) => {
                if (errors.length !== 0) { console.log(errors); return (
                  <Card
                      title={<>{I18n.get("Error Loading Data")} </>}
                      content={<p>{errorFetch(errors[0])}</p>}
                      />);
                    }
                if (loading || !jobsByEngineerJobProgression) return (
                  <Card
                      title={<>{I18n.get("Loading...")}<Spinner animation="border" /> </>} />);
                if (jobsByEngineerJobProgression.items.length === 0) return (
                  <Card
                      title={I18n.get("You currently have no closed jobs.")}
                  />);
                return (<div>{jobsByEngineerJobProgression.items.map((item, key) => {
                    return <JobClosed vatType={this.props.vatType} key={key} job={item} />
                })} </div>);
            }}
        </Connect>
        if (this.props.userID === undefined) {
            content = <Card
                title={<>{I18n.get("Loading...")}<Spinner animation="border" /> </>} />
        }
        return (
            <div className="content">
                <Container fluid>
                    <h2><span className="numberDot active">&nbsp;</span>{I18n.get("Archive")}</h2>

                    {content}
                </Container>
            </div>
        );
    }
}

export default ClosedJobs;
