
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

//Make env globally available as "window.amplify_env"
let idx = awsconfig.aws_user_files_s3_bucket.lastIndexOf("-") + 1;
let env = awsconfig.aws_user_files_s3_bucket.substring(idx);
window.amplify_env = env;

//Fix oauth redirect urls
if (window.location.hostname === 'localhost') {
    awsconfig.oauth.redirectSignIn = "http://localhost:3000/";
    awsconfig.oauth.redirectSignOut = "http://localhost:3000/";
} else {
    if (env === "prod")
        env = "app";
    awsconfig.oauth.redirectSignIn = `https://${env}.silimuse.com/`;
    awsconfig.oauth.redirectSignOut = `https://${env}.silimuse.com/`;
}

//Configure amplify
Amplify.configure(awsconfig)
