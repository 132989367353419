/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

import logo from "assets/img/logo_white.png";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">


        <div className="logo d-block d-lg-none">
          <span

            className="logo-sili"
          >
              <span className="helper-align-middle"></span><img className="align-middle" src={logo} alt="Silimuse" />

          </span>

        </div>



        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        <Navbar.Collapse className="justify-content-end">
          <AdminNavbarLinks layout={this.props.layout}/>
        </ Navbar.Collapse>
      </Navbar>
    );
  }
}
/*
<Navbar.Toggle aria-controls="responsive-navbar-nav" />
<Navbar.Collapse id="responsive-navbar-nav">
<Navbar.Toggle onClick={this.mobileSidebarToggle} />
<Navbar.Collapse>*/


export default AdminNavbar;
