/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { graphqlOperation, I18n } from 'aws-amplify';
import { Connect } from "aws-amplify-react";
import * as queries from 'graphql/SILqueries';
import { Spinner } from "react-bootstrap";
import { extras, listFetch } from "variables/SiliVariables";

function tierSwitch(tier){
  switch (tier) {
    case "BRONZE":
      return I18n.get("Basic");
    case "SILVER":
      return I18n.get("Standard");
    case "GOLD":
      return I18n.get("Professional");
    default:
      return I18n.get("Basic");
}
}

class Vouchers extends Component {

  render() {
    let content = <Connect query={graphqlOperation(queries.codesByUser, { username: this.props.userID })}>
    {({ data, loading, errors }) => {
      if (errors.length !== 0) { console.log(errors); return (
        <Card
        title={<>{I18n.get("Error Loading Vouchers")}
        </>}
        content={<p>errorFetch(errors[0])</p>}
        />);
      }
      if (loading || !data) return (
        <Card
        title={<>{I18n.get("Loading...")}<Spinner animation="border" /> </>} />);
        if (data.codesByUser.items.length === 0) return null;
        return (
          <Card
          title={I18n.get("Your vouchers:")}
          content={<>
          {data.codesByUser.items.map((item, key) => {

            let date = new Date(item.usedDate);
            let month = date.getMonth();
            let day = date.getDate();
            let year = date.getFullYear();
            console.log(month+1);
            return <div className={item.usedDate!==0 ? "voucherList expired" : "voucherList"} key={key}>
                    <code>{item.code}</code> <span className="redeemDate">{item.usedDate!==0 ? `${I18n.get("Redeemed on date:")} ${day}/${month}/${year}` : I18n.get("Not yet redeemed")}</span>
                    <p>
                    {I18n.get("Valid for a")} {
                      tierSwitch(item.tier)
                       } {I18n.get("mix. ")}
                    {item.extras!=null && I18n.get("Includes:")}
                    <ul>
                    {item.extras!=null && item.extras.map((i)=> {return listFetch(extras,i)})}
                    </ul>

                    </p>
                  </div>
          })}
          </>}
          />);
        }}
        </Connect>

        if (this.props.userID === undefined) {
          content = <Card
          title={<>{I18n.get("Loading...")}<Spinner animation="border" /> </>} />
        }

        return content
      }
    }

    export default Vouchers;
