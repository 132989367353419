/* tslint:disable */
/* eslint-disable */
// this is my modified file

export const onUpdateJob = /* GraphQL */ `
  subscription OnUpdateJob($id: ID!) {
    onUpdateJob(id: $id) {
      id
      createDate
      endDate
      finalMix {
        url
      }
      status
      createdAt
      updatedAt
    }
  }
`;
