/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";

import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import Select from 'react-select';

import FixRequiredSelect from "components/FormInputs/FixRequiredSelect";
import {worldCountriesOptions, euCountriesList, provinceIta} from "variables/Countries";

import { I18n } from 'aws-amplify';

export class FiscalInfo extends Component {
  constructor(props) {
    super(props);
    let oldindcomp= "";
    let oldvies= false;
    if (this.props.vatType!==undefined && this.props.vatType!==null){
      if(this.props.vatType.includes("PRIVATE")) {
        oldindcomp= "individual";
      }else {
        oldindcomp= "company";
        if (this.props.vatType==="EUR_VAT"){
          oldvies =true;
        }
      }
    }
    let submitText= "Submit";
    if (this.props.submitText!==undefined){
      submitText =this.props.submitText;
    }

    if (this.props.submitText!==undefined){
      submitText =this.props.submitText;
    }
    this.state = {
      validated: false,
      loading: false,
      submitText: submitText,
      indcomp: oldindcomp,

      fullname: this.props.companyName,
      name: this.props.name,
      surname: this.props.surname,
      address: this.props.address,
      city: this.props.city,
      postcode: this.props.postCode,
      country: this.props.country,
      taxcode: this.props.vatNumber,
      province: this.props.provincia,
      sdi: this.props.codiceDestinatario,
      pec: this.props.PEC,
      vies: oldvies
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  SelectRequired = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={Select}
    options={props.options}
  />
);

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.onAnySubmit !== undefined){
      this.props.onAnySubmit();
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.setState({
        loading: true, submitText: <span><Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        />{I18n.get("Loading...")}</span>
    })
    this.props.onValidSubmit(this.state);
  }
};
  render() {

    return (
      <div>
        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

          <Form.Group controlId="valid02">
            <Form.Label>{I18n.get("Individual/Company")}</Form.Label>
            <Form.Control as="select"
              name="indcomp"
              value={this.state.indcomp}
              onChange={this.onChange}
              required
              custom>
              <option disabled value="">{I18n.get("Please select...")}</option>
              <option value="individual">{I18n.get("Individual")}</option>
              <option value="company">{I18n.get("Company")}</option>
            </Form.Control>
          </Form.Group>


          {this.state.indcomp!==""  &&
          <>
          {this.state.indcomp==="individual" ?
            <Row>
            <Form.Group className="col-sm-6" controlId="ind00">
              <Form.Label>{I18n.get("Name:")}</Form.Label>
              <Form.Control
                name="name"
                value={this.state.name}
                onChange={this.onChange}
                required
              >
              </Form.Control>
            </Form.Group>
            <Form.Group className="col-sm-6" controlId="ind01">
              <Form.Label>{I18n.get("Surname:")}</Form.Label>
              <Form.Control
                name="surname"
                value={this.state.surname}
                onChange={this.onChange}
                required
              >
              </Form.Control>
            </Form.Group>
          </Row> :
          <Form.Group controlId="comp01">
            <Form.Label>{I18n.get("Company name:")}</Form.Label>
            <Form.Control
              name="fullname"
              value={this.state.fullname}
              onChange={this.onChange}
              required
            >
            </Form.Control>
          </Form.Group>
        }
        <Form.Group controlId="ind04">
          <Form.Label>{I18n.get("Country:")}</Form.Label>
          <this.SelectRequired
            placeholder=""
            defaultValue={worldCountriesOptions.find(x => x.value===this.state.country)}
            required={true}
            onChange={(selection) => this.onChange({ target: { name: "country", value: selection.value } })}
            options={worldCountriesOptions}
            selectComponent={Select}
          />
        </Form.Group>
        <Row>
          <Form.Group className="col-sm-8" controlId="ind02">
            <Form.Label>{I18n.get("Address:")}</Form.Label>
            <Form.Control
              name="address"
              value={this.state.address}
              onChange={this.onChange}
              required
            >
            </Form.Control>
          </Form.Group>
          <Form.Group className="col-sm-4" controlId="ind02b">
            <Form.Label>{I18n.get("Post code:")}</Form.Label>
            <Form.Control
              name="postcode"
              value={this.state.postcode}
              onChange={this.onChange}
              required
            >
            </Form.Control>
          </Form.Group>
        </Row>
          <Row>
          <Form.Group className={this.state.country==="IT" ? "col-sm-8" :"col-12"} controlId="ind02c">
            <Form.Label>{I18n.get("City:")}</Form.Label>
            <Form.Control
              name="city"
              value={this.state.city}
              onChange={this.onChange}
              required
            >
            </Form.Control>
          </Form.Group>
          {this.state.country==="IT" &&
            <Form.Group className="col-sm-4" controlId="ind03">
            <Form.Label>{I18n.get("Provincia")}</Form.Label>
              <this.SelectRequired
                placeholder=""
                required={true}
                defaultValue={provinceIta.find(x => x.value===this.state.province)}
                onChange={(selection) => this.onChange({ target: { name: "province", value: selection.value } })}
                options={provinceIta}
                selectComponent={Select}
              />
          </Form.Group>}

        </Row>



          <Form.Group controlId="ind05">
            <Form.Label>{this.state.indcomp==="individual" ?
              ((this.state.country!=="IT" && euCountriesList.includes(this.state.country)) ?
                <>{I18n.get("TIN code - Personal Fiscal Identifier: ")}
                <a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/taxation_customs/tin/pdf/en/TIN_-_subject_sheet_-_3_examples_en.pdf">{I18n.get("(what is my TIN code?)") }</a>
                </>:
                I18n.get("Tax code:")
              ) :
              I18n.get("VAT code:")}</Form.Label>
            <Form.Control
              name="taxcode"
              value={this.state.taxcode}
              onChange={this.onChange}
              required
            >
            </Form.Control>
          </Form.Group>
          </>
      }
      {this.state.indcomp==="company"  && this.state.country==="IT" &&
        <>
        <Form.Group controlId="it01">
          <Form.Label>{I18n.get("Indirizzo PEC: (non obbligatorio per regime forfettario)")}</Form.Label>
          <Form.Control
            name="pec"
            value={this.state.pec}
            onChange={this.onChange}
            required={!this.state.forfe}
          >
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="it02">
          <Form.Label>{I18n.get("Codice Destinatario SDI: (non obbligatorio per regime forfettario)")}</Form.Label>
          <Form.Control
            name="sdi"
            value={this.state.sdi}
            onChange={this.onChange}
            required={!this.state.forfe}
          >
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="it03">
          <Form.Check
            name="forfe"
            type="checkbox"
            label="Titolare di P.IVA con regime forfettario"
            onChange={(e)=>this.onChange({target:{name:"forfe",value:e.target.checked}})}
            checked={this.state.forfe}
            />
        </Form.Group>
        </>}
        {this.state.indcomp==="company"  && this.state.country!=="IT" && euCountriesList.includes(this.state.country) &&
          <>

          <Form.Group controlId="eu01">
            <Form.Check
              name="vies"
              type="checkbox"
              onChange={(e)=>this.onChange({target:{name:"vies",value:e.target.checked}})}
              checked={this.state.vies}
              label={<>{I18n.get("The company is registered to the ")} <a target='_blank' rel="noopener noreferrer" href='https://ec.europa.eu/taxation_customs/vies/'>{I18n.get("V.I.E.S.")}</a></>}

              />
          </Form.Group>
          </>}


          <Form.Group controlId="tos">
            <Form.Check
              required
              type="checkbox"
              label={<>{I18n.get("Agree to our")} <a target='_blank' rel="noopener noreferrer" href='https://www.silimuse.com/terms_of_service'>{I18n.get("Terms of Service")}</a></>} />
          </Form.Group>
          <Form.Group controlId="pripol">
            <Form.Check
              required
              type="checkbox"
              label={<>{I18n.get("Agree to our")} <a target='_blank' rel="noopener noreferrer" href='https://www.iubenda.com/privacy-policy/43644172'>{I18n.get("Privacy Policy")}</a></>} />
          </Form.Group>
          <Form.Group controlId="truth">
            <Form.Check
              required
              type="checkbox"
              label={<>{I18n.get("The customer, in accordance with art. 21 of DPR 633/72, assumes full responsibility for the above data provided, and is committed to communicate any eventual changes.")}</>} />
          </Form.Group>
          <Row>
            <Col xs="8">
            </Col>
            <Col xs="4">
              {!this.props.hideSubmit &&
              <Button className="btn-round" type="submit" active={this.state.loading} disabled={this.state.loading}>{this.state.submitText}</Button>}
            </Col>
          </Row>
        </Form>
    </div>
    );
  }
}

export default FiscalInfo;
