import React, { Component } from "react";
import { Form } from "react-bootstrap";

import { I18n } from 'aws-amplify';

/**
* A component to select an audio file from user's computer
*
* Will call "props.onUpdateTrack" with selected file
*
* */
export default class SelectAudioFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      playing: null,
      audio: null,
      label: I18n.get("Choose audio file...")
    }

    this.changeAudio = this.changeAudio.bind(this);
    this.playPause = this.playPause.bind(this);
  }

  changeAudio(e) {
    this.props.onUpdateTrack(e);

    //Create audio player
    let audioObj = this.state.audio;
    if (!audioObj)
    audioObj = new Audio();

    //Obtain audio file
    var target = e.currentTarget;
    var file = target.files[0];

    const onEndedFn = () => { this.setState({ playing: false }); };

    if (target.files && file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        audioObj.src = e.target.result;
        audioObj.onended = onEndedFn;
      }
      reader.readAsDataURL(file);
    }

    //Assign new audio
    this.setState({ audio: audioObj, playing: false, label: file.name });
  }

  playPause() {
    if (!this.state.playing)
    this.state.audio.play();
    else
    this.state.audio.pause();

    this.setState({ playing: !this.state.playing });
  }

  render() {
    let styleclosed = { width: "100%", display: "inline-block", "verticalAlign": "middle" };
    let styleopen = { width: "95%", display: "inline-block", "verticalAlign": "middle" };
    let audioDisplay = this.state.audio ? "inline-block" : "none";

    return (
      <>
      <br/>
      <div style={{ width: "5%", "display": audioDisplay }}>
        <button type="button" className="btn btn-block btn-icon" onClick={this.playPause}>
          <span className={this.state.playing ? "fa fa-pause fa-1x" : "fa fa-play fa-1x"} title="play" ></span>
        </button>
      </div>

      <div style={this.state.audio ? styleopen : styleclosed}>
        <Form.File
          className={this.state.audio === null && "placeholder"}
          label={this.state.label}
          accept="audio/*"
          name="fname"
          onChange={(e) => this.changeAudio(e)}
          custom
          />
      </div>



      </>
  );
}
}
