import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { I18n } from 'aws-amplify';

export default class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>404</h1>
                <h2>{I18n.get("This page does not exist.")}</h2>
                <p>{I18n.get("Please go back to the ")}<Link to="/">{I18n.get("home")}</Link>.</p>
            </div>
        )
    }
}
