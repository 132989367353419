/*!


*/
import React, { Component } from "react";
import { Container } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";

import { I18n } from 'aws-amplify';


class EngineerNotAuthorized extends Component {
    render() {
        return (
            <div className="content">
                <Container fluid>
                    <Card
                        title={I18n.get("Welcome to Silimuse")}
                        content={<>
                          <p>
                            {I18n.get("Thank you for wanting to join Silimuse to offer your services through our platform.")}
                          </p>
                            <p>
                              {I18n.get("Silimuse is currently a publicly available BETA. This means that we are still not open to sound engineers wanting to join our community, as we prefer testing our system with few selected professionals.")}
                            </p>
                            <p>
                              {I18n.get("To get notified about the development of our project, and when it will be open to sound engineers, please compile the module available at ")}<a href="https://www.silimuse.com/keepcontact">{I18n.get("this page.")}</a>
                            </p>
                            <p>
                              {I18n.get("We are looking forward to working with you!")}
                            </p>
                            </>
                        }
                    />
                </Container>
            </div>
        );
    }
}

export default EngineerNotAuthorized;
