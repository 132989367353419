import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";

import Select, { components as SelectComponents } from 'react-select';


const Option = props => {
    return (
        <Col xl="3" lg="4" md="3" sm="6">
            <SelectComponents.Option {...props} />
        </Col>
    );
};

const MenuList = props => {
    return (
        <SelectComponents.MenuList {...props}>
            <Row style={{padding:0, margin:0}}>{props.children}</Row>
        </SelectComponents.MenuList>
    );
};


export default class SelectGrid extends Component {

    render() {
        return (
            <Select
                {...this.props}
                components={{ MenuList, Option }}
            />
        );
    }
}
