import React, { useContext } from "react";
import { Col, Row, Accordion, Button, Popover, OverlayTrigger} from "react-bootstrap";
import { instruments, adjectives, reviewQualifiers, listFetch, helperDescriptions } from "variables/SiliVariables";
import Select from 'react-select';
import { I18n } from 'aws-amplify'

import AccordionContext from 'react-bootstrap/AccordionContext';
import ReviewWave from "./ReviewWave";

function StyledToggle({ eventKey, islast }) {
  const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <div className={isCurrentEventKey ? 'expanded' : 'collapsed'}>
    </div>
  );
}


/**
* A component that shows review input 2nd level
*
* props.onDataUpdate - called when data gets updated by user
* props.readOnly - prevent changes to review forms
*
* */

const selectStyle = {
  menu: (provided, state) => ({
    ...provided,
    "z-index": 5
  }),

}

class TrackReview extends React.Component {
  constructor(props) {
    super(props);

    let reviewData = [];

    if (props.value) {
      //Set as old value
      reviewData = props.value;
    } else {
      //Prepare new list with default values
      let trs = props.job.tracklist;
      for (var i = 0; i < trs.length; i++) {
        reviewData.push({
          number: trs[i].number,
          volume: 0,
          effect: 0,
          eq: 0,
          adjective: 0,
          waveformParams : []
        });
      }
    }

    this.state = {
      reviewData: reviewData,
      activeKey: 0,
      mounted: false,
    };

    this.updateRow = this.updateRow.bind(this);
  }


  updateRow(id, val) {
    let newReviewData = this.state.reviewData;

    let idx = newReviewData.findIndex((el) => el.number === id);
    newReviewData[idx] = val;

    this.setState({ newReviewData });
    this.props.onDataUpdate(newReviewData);
  }

  componentDidMount(){
    this.setState({mounted:true})
  }

  render() {
    const hideOnMobileClass = "d-none d-md-block";

    function popover(title,element) {
      return (
        <Popover id="popover-basic">
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>
            {element}
          </Popover.Content>
        </Popover>
      );
    }


    //Tracks level review
    return (<>
      <Accordion activeKey={this.state.activeKey} className="trackTable" onSelect={(e) => this.setState({ activeKey: e })}>

        {this.props.job.tracklist.map((tr) => {

          let currReview = this.state.reviewData[tr.number];

          return (<div key={tr.number}>
            <StyledToggle eventKey={tr.number} islast={true} />
            <Accordion.Toggle as={"div"} className="trackToggle" eventKey={tr.number}>
              <Row className="align-items-center">
                <Col md={11} className={hideOnMobileClass} style={{ "paddingRight": "0px" }}>
                  {listFetch(instruments, tr.instrument)}
                </Col>
                <Col xs={1} md={1}>
                  <Button variant="btn-block btn-icon"><i className="fa fa-caret-down" /></Button>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={tr.number} >
              <div className="trackInput">

                <h4>{I18n.get("About the entire track")}</h4>
                <Row className="align-items-center trackSelectors">
                  <Col md={6}>
                    <p className="form-label">{I18n.get("Volume")}&nbsp;
                      <OverlayTrigger  placement="top" overlay={popover("Volume",helperDescriptions.volume)}>

                      <span className="fa fa-question-circle fa-1x" title="info" ></span>
                      </OverlayTrigger>

                    </p>
                    <Select
                      styles={selectStyle}
                      value={reviewQualifiers.volume.find(op => op.value === currReview.volume)}
                      options={reviewQualifiers.volume}
                      onChange={(val) => this.updateRow(tr.number, { ...currReview, volume: val.value })}
                      isDisabled={this.props.readOnly} />
                  </Col>


                  <Col md={{ span: 6, offset: 0 }}>
                    <p className="form-label">{I18n.get("Effect")} &nbsp;
                      <OverlayTrigger  placement="top" overlay={popover("Effect",helperDescriptions.effect)}>

                      <span className="fa fa-question-circle fa-1x" title="info" ></span>
                      </OverlayTrigger>

                      </p>
                    <Select
                      styles={selectStyle}
                      value={reviewQualifiers.effect.find(op => op.value === currReview.effect)}
                      options={reviewQualifiers.effect}
                      onChange={(val) => this.updateRow(tr.number, { ...currReview, effect: val.value })}
                      isDisabled={this.props.readOnly} />
                  </Col>


                  <Col md={{ span: 6, offset: 0 }}>
                    <p className="form-label">{listFetch(adjectives, tr.adjectives[0])}&nbsp;
                      <OverlayTrigger  placement="top" overlay={popover("Adjective",helperDescriptions.adjective)}>
                      <span className="fa fa-question-circle fa-1x" title="info" ></span>
                      </OverlayTrigger>
                      </p>

                    <Select
                      styles={selectStyle}
                      value={reviewQualifiers.adjective.find(op => op.value === currReview.adjective)}
                      options={reviewQualifiers.adjective}
                      onChange={(val) => this.updateRow(tr.number, { ...currReview, adjective: val.value })}
                      isDisabled={this.props.readOnly} />
                  </Col>

                  <Col md={{ span: 6, offset: 0 }}>
                    <p className="form-label">{I18n.get("Equalization")}&nbsp;
                      <OverlayTrigger  placement="top" overlay={popover("Equalization",helperDescriptions.eq)}>
                      <span className="fa fa-question-circle fa-1x" title="info" ></span>
                      </OverlayTrigger>
                    </p>
                    <Select
                      styles={selectStyle}
                      value={reviewQualifiers.eq.find(op => op.value === currReview.eq)}
                      options={reviewQualifiers.eq}
                      onChange={(val) => this.updateRow(tr.number, { ...currReview, eq: val.value })}
                      isDisabled={this.props.readOnly} />
                  </Col>

                </Row>

                {this.props.hasWaveform &&
                  <>
                  <hr />
                  <h4>{I18n.get("Time specific edits")}</h4>
                  <p>{I18n.get("Drag over the interested section to specify your requests. Double click a region to loop it.")}</p>
                  <ReviewWave
                    currentlyVisible = {this.state.activeKey==tr.number}
                    readOnly={this.props.readOnly}
                    song={this.props.job.reviews.slice(-1)[0].file.url}
                    value={this.props.value && this.props.value[tr.number].waveformParams}
                    onDataUpdate={(waveList) => this.updateRow(tr.number, { ...currReview,
                    waveformParams : waveList})}
                    adjective={listFetch(adjectives, tr.adjectives[0])}
                    />
                  </>
              }
            </div>
          </Accordion.Collapse>
        </div>);
      })}

    </Accordion>
    </>);
  }
}

export default TrackReview;
