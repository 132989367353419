/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
//import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import 'aws-exports-config.js'
import "./strings";
import { Authenticator, ForgotPassword} from 'aws-amplify-react';

import AppWrapper from "./AppWrapper";

import SignUp from "components/Auth/SignUp";
import SignIn from "components/Auth/SignIn";
import ConfirmSignUp from "components/Auth/ConfirmSignUp";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-P6LWQ79',
    dataLayer: {
         'event': "sil_main"
    }
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(

    <AppWrapper />

 ,document.getElementById("root"));
