import React, { Component } from "react";
import { Alert, Container, Row, Form, Button, Col } from "react-bootstrap";
import { Card } from "components/Card/Card";

import { Auth, I18n } from 'aws-amplify';
import TagManager from 'react-gtm-module';

export class SignUp extends Component {
    constructor(props) {
        super(props)
        this._validAuthStates = ["signUp"];
        this.state = {
            email: undefined,
            password: undefined,
            name: undefined,
            validated: false,
            showRegError: false,
            regError: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);

    };

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    async SignUp(eml, pwd, nam) {

        try {
            await Auth.signUp({
                "username": eml,
                "password": pwd,
                "attributes": {
                    "name": nam
                }
            });
            TagManager.dataLayer({ dataLayer: {event: "sil_presignup"} });
            this.props.onStateChange("confirmSignUp", { "email": this.state.email, "password": this.state.password });
        } catch (error) {
            this.setState(
                {
                    showRegError: true,
                    validated: false,
                    regError: error.message
                });
            console.log('error signing up', error);
        }
    };

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            this.SignUp(this.state.email, this.state.password, this.state.name);
        }
        this.setState({ validated: true });
    };


    render() {
        if (!this._validAuthStates.includes(this.props.authState)) {
            return null;
        }
        return (
            <Container className="mt-5">
                <Row>
                    <Col lg="3" />
                    <Col>
                        <Card title={I18n.get("Register to Silimuse")}
                            content={
                              <>
                              <Row>
                                <Form.Group as={Col} xs="12" >
                                  <Button className="btn-social" onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })} block><i className="fa fa-facebook-official fa-3x"></i>
                                  <span className="pl-5">{I18n.get("Register with Facebook")}</span></Button>
                                </Form.Group>
                                <Form.Group as={Col} xs="12" >
                                  <Button className="btn-social" onClick={() => Auth.federatedSignIn({ provider: 'Google' })} block>
                                    <i className="fa fa-google fa-3x"></i><span className="pl-5">{I18n.get("Register with Google")}</span>
                                  </Button>
                                </Form.Group>
                              </Row>
                              <p className="middleLine">{I18n.get("or")}</p>

                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                  <p className="category">{I18n.get("* All fields are required.")}</p>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Label>{I18n.get("Full Name: *")}</Form.Label>

                                        <Form.Control
                                            required
                                            name="name"
                                            type="text"
                                            placeholder="John Smith"
                                            value={this.state.name}
                                            onChange={this.onChange}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom02">
                                        <Form.Label>{I18n.get("Email Address: *")}</Form.Label>

                                        <Form.Control
                                            required
                                            name="email"
                                            type="email"
                                            placeholder="you@yourmail.com"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {I18n.get("Please insert a valid email address.")}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback>{I18n.get("Looks good!")}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="validationCustomUsername">
                                        <Form.Label>{I18n.get("Password: *")}</Form.Label>

                                        <Form.Control
                                            name="password"
                                            type="password"
                                            placeholder="********"
                                            value={this.state.password}
                                            onChange={this.onChange}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            required
                                            label={<>{I18n.get("Agree to our")} <a target='_blank' rel="noopener noreferrer" href='https://www.silimuse.com/terms_of_service'>{I18n.get("Terms of Service")}</a> *</>}
                                            feedback={I18n.get("You must agree before submitting.")}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            required
                                            label={<>{I18n.get("Agree to our")} <a target='_blank' rel="noopener noreferrer" href='https://www.iubenda.com/privacy-policy/43644172'>{I18n.get("Privacy Policy")}</a> *</>}
                                            feedback={I18n.get("You must agree before submitting.")}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Alert variant="danger" show={this.state.showRegError}>
                                            {this.state.regError}
                                        </Alert>
                                    </Form.Group>
                                    <Row>
                                        <Col xs="8">
                                            <p>{I18n.get("Already registered?")}<br /><a href="#signin" onClick={() => this.props.onStateChange("signIn")}>{I18n.get("Sign In")}</a></p>

                                        </Col>
                                        <Col xs="4">
                                            <Button className="btn-round" type="submit">{I18n.get("Register")}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </>}
                        />
                    </Col>
                    <Col lg="3" />
                </Row>
            </Container >
        );
    }
}

export default SignUp;
