/*!

Silimuse Beta

*/
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  Button
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import  Vouchers  from "components/Card/Vouchers.jsx";
import FiscalInfo from "components/FormInputs/FiscalInfo";

import { API, graphqlOperation, Auth, I18n } from 'aws-amplify';
import * as queries from 'graphql/SILqueries';
import * as mutations from 'graphql/SILmutations';
import {euCountriesList} from "variables/Countries";

import {errorFetch} from "variables/SiliErrors";


class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      mail: I18n.get("Loading..."),
      name: I18n.get("Loading...")
    }
    this.changePassword = this.changePassword.bind(this);
    this.fetchInfo = this.fetchInfo.bind(this);
    this.updateInfo = this.updateInfo.bind(this);

    this.fetchInfo();
  }

  async fetchInfo() {
    Auth.currentAuthenticatedUser()
    .then(user => {
      this.setState({ "mail": user.attributes.email, "name": user.attributes.name });
    })
    .catch(err => {
      this.setState({ ready: true, resultInfo: <Alert variant="danger">Error loading info: {errorFetch(err)}</Alert> });
      console.log(err);
    });

    if (this.props.role==="musician"){
      API.graphql(graphqlOperation(queries.getMusicianUserData, { username: this.props.userID })).then(res => {
        this.setState({ "userData": res.data.getMusicianData.userData, "promoCodes": res.data.getMusicianData.promoCodes });
      })
      .catch(err => {
        this.setState({ ready: true, resultInfo: <Alert variant="danger">Error loading info: {errorFetch(err)}</Alert> });
        console.log(err);
      });
    }else if (this.props.role==="engineer") {
      API.graphql(graphqlOperation(queries.getEngineerUserData, { username: this.props.userID })).then(res => {
        this.setState({ "userData": res.data.getEngineerData.userData });
      })
      .catch(err => {
        this.setState({ ready: true, resultInfo: <Alert variant="danger">Error loading info: {errorFetch(err)}</Alert> });
        console.log(err);
      });
    }
  }

  async changePassword(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ passvalidated: true });
      return;
    }
    this.setState({ ready: false, passvalidated: true });
    Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, this.state.oldPass, this.state.newPass);
    })
    .then(data => {
      this.setState({ ready: true, resultPass: <Alert variant="success">{I18n.get("Successfully changed password")}</Alert> });
    })
    .catch(err => {
      this.setState({ ready: true, resultPass: <Alert variant="danger">{I18n.get("Error changing password:")} {errorFetch(err)}</Alert> });
      console.log(err);
    });
  }

  async updateInfo(data){
    let vatkind = "EXTRA_VAT";
    if (euCountriesList.includes(data.country) && "IT"!==data.country) {
      if (data.indcomp === "individual") {
        vatkind= "EUR_PRIVATE";
      }else if (data.vies===true) {
        vatkind= "EUR_VAT";
      }else{
        vatkind= "EUR_VAT_NO_VIES";
      }
    }else if ("IT"===data.country) {
      if (data.indcomp === "individual") {
        vatkind= "ITA_PRIVATE";
      }else if (data.forfe===true) {
        vatkind= "ITA_PIVA_FORFET";
      }else{
        vatkind= "ITA_PIVA";
      }
    }else if (data.indcomp === "individual") {
      vatkind = "EXTRA_PRIVATE";
    }

    let collectedInfo= {
      acceptedTermsVersion: 1,
      vatType: vatkind,
      address: data.address,
      city: data.city,
      postCode: data.postcode,
      country: data.country,
      vatNumber: data.taxcode
    };
    if (data.country==="IT") {
      collectedInfo["provincia"] = data.province;
      if (data.indcomp ==="company"){
      collectedInfo["codiceDestinatario"] = data.sdi;
      collectedInfo["PEC"] = data.pec;
      }
    }
    if (vatkind.includes("PRIVATE")) {
      collectedInfo["name"] = data.name;
      collectedInfo["surname"] = data.surname;
    }else {
      collectedInfo["companyName"] = data.fullname;
    }

    try {
      let res;
      if (this.props.role==="musician") {
        res = await API.graphql(graphqlOperation(mutations.updateMusicianData, {"input": {"userData": collectedInfo }}));
        this.setState({ "userData": res.data.updateMusicianData.userData, updated:true, resultInfo: <Alert variant="success">{I18n.get("Successfully updated invoice information")}</Alert> });
      } else if (this.props.role==="engineer") {
        res = await API.graphql(graphqlOperation(mutations.updateEngineerData, {"input": {"userData": collectedInfo }}));
        this.setState({ "userData": res.data.updateEngineerData.userData, updated:true, resultInfo: <Alert variant="success">{I18n.get("Successfully updated invoice information")}</Alert> });
      }

    } catch (error) {
      this.setState({ ready: true, resultInfo: <Alert variant="danger">Error updating info: {errorFetch(error)}</Alert> });
      console.log(error);

    }
  };

  render() {
    if (!this.state.userData) {
      return (
        <div className="content">
          <Container fluid>
            <Card content={<h3>Loading...</h3>}/>
          </Container>
        </div>
      );
    }
    return (
      <div className="content">
        <Container fluid>
          <Card
            title={I18n.get("Invoice Information")}
            content={<>

              <FiscalInfo {... this.state.userData} hideSubmit={this.state.updated} submitText="Update information" onValidSubmit={this.updateInfo}/>
                {this.state.resultInfo}
              </>
            }
            />
          <Card
            title={I18n.get("Access Information")}
            content={(this.props.userID.indexOf("Facebook")===0 && <p>You are registered through Facebook: visit <a target="_blank" rel="noopener noreferrer" href="http://facebook.com/settings">facebook.com/settings</a> if you wish to change your password.</p> ) || (this.props.userID.indexOf("Google")===0 && <p>You are registered through Google: visit <a target="_blank" rel="noopener noreferrer" href="http://myaccount.google.com">myaccount.google.com</a> if you wish to change your password.</p> ) ||
              <Form noValidate validated={this.state.passvalidated} onSubmit={this.changePassword}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: I18n.get("Email Address"),
                      type: "text",
                      value: this.state.mail,
                      disabled: true
                    }
                  ]}
                  />
                <h4>Change Password: </h4>
                <Form.Row>
                  <Col md={6}>
                    <Form.Group>
                    <Form.Label>{I18n.get("Old Password")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="****"
                      name="oldPass"
                      required
                      onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                      />
                  </Form.Group>

                  </Col>
                  <Col md={6}>
                    <Form.Group>
                    <Form.Label>{I18n.get("New Password")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="****"
                      name="newPass"
                      required
                      onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                      />
                  </Form.Group>
                  </Col >
                </Form.Row>
                <Row>
                  <Col xs="8">
                  </Col>
                  <Col xs="4">
                    <Button className="btn-round" type="submit" disabled={!this.state.ready} active={!this.state.ready}>{I18n.get("Change Password")}</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.resultPass}
                  </Col>
                </Row>
              </Form>
            }
            />
          <Vouchers
            {... this.props}/>
        </Container>
      </div>
    );
  }
}

export default UserProfile;
