/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";
import { API, graphqlOperation, I18n } from 'aws-amplify'
import * as mutations from 'graphql/SILmutations';
import { extras, genres, listFetch } from "variables/SiliVariables";
import { Button, Alert, Spinner, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "components/Card/Modal";
import {vatprice} from "variables/SiliVariables";

import {errorFetch} from "variables/SiliErrors";

export class JobCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            result: "",
            accepted: false
        }
        this.acceptModal = React.createRef();

        this.accept = this.accept.bind(this);
    }

    async accept() {
        try {
            this.setState({ accepted: true })
            // Assign Job mutation. Requires JOB id.
            await API.graphql(graphqlOperation(mutations.assignJob, { id: this.props.job.id }));
            this.setState({ result: <Alert variant="success">{I18n.get("Successfully accepted job. Start working on the")} <Link to="./open">{I18n.get("Open Jobs page")}</Link></Alert> });
        } catch (err) {
            // This is bad
            console.log('error: ', err);
            this.setState({ result: <Alert variant="danger">{errorFetch(err)}</Alert> })
        }
    }

    render() {
        let date = new Date(this.props.job.createDate);
        let month = date.getMonth();
        let day = date.getDate();
        let hoursAvailable = Math.floor(this.props.job.availableTime/3600000);
        let minutesAvailable = Math.floor((this.props.job.availableTime%3600000)/60000);
        let action;
        if (this.props.action === "accept") {
            if (this.state.result === "") {
                action = <>
                    <Button disabled={this.state.accepted} active={this.state.accepted}  onClick={() => this.acceptModal.current.show()}> {I18n.get("Accept Job")}{this.state.accepted ? <Spinner as="span" animation="border" size="sm" /> : ""}</Button>
                    <Modal ref={this.acceptModal} title={I18n.get("Confirmation")} message={I18n.get("Are you sure to accept this job? If you do you are required to complete it within "+hoursAvailable + ":"+ minutesAvailable.toString().padStart(2, "0") +" hours. This cannot be undone.")} actionMessage={I18n.get("Accept")} callback={this.accept} />
                </>
            } else {
                action = this.state.result
            }
        }
        return (
            <div className="card">
                <div className="header">
                    <h4 className="title">{listFetch(genres, this.props.job.genre)} {I18n.get("Song")} - <i className="fa fa-eur" /> {vatprice(this.props.job.prize,"EUR_VAT")} <br /></h4>
                </div>
                <div className="content">
                    <p>
                        {I18n.get("Number of tracks:")} {this.props.job.tracklist.length}
                    </p>
                    <p>
                        {I18n.get("Date created:")} {day}/{month + 1}
                        <br />
                    </p>
                    <p>
                      {I18n.get("Required Services:")}
                      </p>
                      <ul>
                        <li>{I18n.get("Mix & master")}</li>
                    {this.props.job.extras != null &&
                      this.props.job.extras.map(ex =>
                        <li>{I18n.get(listFetch(extras, ex))}</li>
                      )
                    }
                  </ul>

                  <p>
                    {I18n.get("This job must be completed within "+hoursAvailable + ":"+ minutesAvailable.toString().padStart(2, "0") +" hours from the moment it is accepted.")}
                  </p>
                    <h5>{I18n.get("References for this song:")}</h5>
                    {this.props.job.references.map((el, i) => {
                        return (
                            <Container fluid key={i}>
                                <p>{el.referenceType === 0 ? I18n.get("Vocal reference:") : I18n.get("Instrumental reference:")}</p>
                                <iframe title={"chosen"} src={el.url} width="250" height="75" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            </Container>)
                    })}
                    <div className="reward">

                        {action}
                    </div>

                </div>
            </div>
        );
    }
}

export default JobCard;
