/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { Auth, I18n } from 'aws-amplify';
import { Link } from 'react-router-dom';


class AdminNavbarLinks extends Component {
    checkUser() {
        Auth.currentAuthenticatedUser()
            .then(user => console.log({ user }))
            .catch(err => console.log(err))
    }

    signOut() {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err))
    }

    render() {
        // const notification = (
        //   <span>
        //     <i className="fa fa-globe" />
        //     <span className="notification">5</span>
        //     <span className="d-lg-none">Notifications</span>
        //   </span>
        // );
        // <NavDropdown
        //   title={notification}
        //   id="basic-nav-dropdown"
        // >
        //   <NavDropdown.Item eventKey={2.1}>Notification 1</NavDropdown.Item>
        //   <NavDropdown.Item eventKey={2.2}>Notification 2</NavDropdown.Item>
        //   <NavDropdown.Item eventKey={2.3}>Notification 3</NavDropdown.Item>
        //   <NavDropdown.Item eventKey={2.4}>Notification 4</NavDropdown.Item>
        //   <NavDropdown.Item eventKey={2.5}>Another notifications</NavDropdown.Item>
        // </NavDropdown>
        // <Nav>
        //   <NavDropdown
        //     title="Hello User!"
        //     id="basic-nav-dropdown-right"
        //   >
        //     <NavDropdown.Item href="./user">Account Settings</NavDropdown.Item>
        //     <NavDropdown.Item onClick={()=>{this.checkUser();}}>Log User Data</NavDropdown.Item>
        //     <NavDropdown.Divider />
        //     <NavDropdown.Item onClick={()=>{this.signOut();}}>Log Out</NavDropdown.Item>
        //   </NavDropdown>
        // </Nav>
        // return (
        //     <Nav>
        //         {window.amplify_env === "dev" && <Nav.Link onClick={() => { this.checkUser(); }}>{I18n.get("Log User Data")}</Nav.Link>}
        //         <Nav.Link as={Link} to="./user" >{I18n.get("Account")}</Nav.Link>
        //         <Nav.Link onClick={() => { this.signOut(); }}>{I18n.get("Log Out")}</Nav.Link>
        //     </Nav>
        //
        // );
        return <Nav/>
    }
}

export default AdminNavbarLinks;
