/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";


import { Alert, Button, Spinner } from "react-bootstrap";




export class IntroCard extends Component {


    render() {

        //Main view
        return (
            <div className="card">

                <div className="header">
                    <h2 className="title">Let us Mix your song for you!</h2>
                </div>
                <div className="content">
                    <p>Silimuse is the easy-to-use AI platform for <strong>automatic mix</strong> available to you for <strong>FREE!</strong></p>
                    <p><strong>Try it now!</strong> Upload your tracks below, our system will mix them automatically for you.</p>
                </div>
            </div>
        );
    }
}

export default IntroCard;
