/*

Silimuse beta

Filippo Carnovalini

*/
import React, { Component } from "react";
import { Alert, Container, Row, Form, Button, Col } from "react-bootstrap";
import { Card } from "components/Card/Card";

import { Auth, I18n } from 'aws-amplify';
import TagManager from 'react-gtm-module';

export class ConfirmSignUp extends Component {
  constructor(props) {
    super(props)
    this._validAuthStates = ["confirmSignUp"];
    this.state = {
      code: undefined,
      validated: false,
      showRegError: false,
      regError: "",
      reSent: false,
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  async resendConfirmationCode() {
    this.setState({ showRegError: false })
    try {
      await Auth.resendSignUp(this.props.authData.email);
      this.setState({ "reSent": true });
      this.setState({ loading: false });

    } catch (err) {
      console.log(err);
      this.setState(
        { showRegError: true, regError: err.message });
      }
    }

    async Confirm() {
      this.setState({ loading: true });
      try {
        await Auth.confirmSignUp(this.props.authData.email, this.state.code);
        TagManager.dataLayer({ dataLayer: {event: "sil_signup_confirmed"} });
        const user = await Auth.signIn(this.props.authData.email, this.props.authData.password);
        this.props.onStateChange("signedIn", user);
        this.setState({ loading: false });
      } catch (error) {
        this.setState(
          {
            showRegError: true,
            validated: false,
            regError: error.message
          });
          console.log('error cofirming up', error);
        }
      };

      handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
          this.Confirm();
        }
        this.setState({ validated: true });
      };

      render() {
        if (!this._validAuthStates.includes(this.props.authState)) {
          return null;
        }
        return (
          <Container className="mt-5">
            <Row>
              <Col lg="3" />
              <Col>
                <Card title={I18n.get("Confirm your registration:")}
                  category={I18n.get("Check your email and paste the code your received.")}
                  content={
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                      <Form.Group controlId="validationCustom01">
                        <Form.Label>Email</Form.Label>

                        <Form.Control
                          required
                          disabled
                          type="mail"
                          placeholder="John Smith"
                          value={this.props.authData.email}
                          />
                      </Form.Group>
                      <Form.Group controlId="validationCustom02">
                        <Form.Label>{I18n.get("Activation Code")}</Form.Label>

                        <Form.Control
                          required
                          type="text"
                          name="code"
                          value={this.state.code}
                          onChange={this.onChange}
                          placeholder={I18n.get("your code")}
                          />
                      </Form.Group>
                      <p>{I18n.get("Cannot find your code? Check your spam folder. You can also ")}<a href="./" onClick={(e) =>{ e.preventDefault(); this.resendConfirmationCode()}}>{I18n.get("request another code.")}</a></p>
                      <Form.Group>
                        <Alert variant="success" show={this.state.reSent}>
                          {I18n.get("Another code sent to your email.")}
                        </Alert>
                        <Alert variant="danger" show={this.state.showRegError}>
                          {this.state.regError}
                        </Alert>
                      </Form.Group>
                      <Alert variant="danger" show={this.state.showRegError} onClick={() => this.resendConfirmationCode()}>
                        {I18n.get("Click HERE to send another code.")}
                      </Alert>
                      <Row>
                        <Col xs="8">
                          <p>{I18n.get("Go back to")}<br /><a href="./" onClick={() => this.props.onStateChange("signIn")}>{I18n.get("Sign In")}</a></p>

                        </Col>
                        <Col xs="4">
                          <Button className="btn-round" type="submit" disabled={this.state.loading} active={this.state.loading}>{I18n.get("Confirm")}</Button>
                        </Col>
                      </Row>
                    </Form>
                  }
                  />
              </Col>
              <Col lg="3" />
            </Row>
          </Container >
        );
      }
    }

    export default ConfirmSignUp;
