const { I18n } = require('aws-amplify');

module.exports.listFetch = function (list, i) {
    let entry = list.find(function (el) {
        return el.value === i
    })
    if (entry === undefined) {
      entry = {value: 0, label: "null"};
    }
    return entry.label
}

module.exports.freqFetch = function (list, i) {
    let entry = list.find(function (el) {
        return el.value === i
    })
    if (entry === undefined) {
      entry = {value: 0, freq: "null"};
    }
    return entry.freq
}

module.exports.genres = [
    { value: 1, label: I18n.get("Blues") },
    { value: 2, label: I18n.get("Classical") },
    { value: 3, label: I18n.get("Country") },
    { value: 4, label: I18n.get("Dance / EDM") },
    { value: 5, label: I18n.get("Electronic") },
    { value: 6, label: I18n.get("Gospel") },
    { value: 7, label: I18n.get("Hip-Hop/Rap") },
    { value: 8, label: I18n.get("Indie Pop") },
    { value: 9, label: I18n.get("Jazz") },
    { value: 10, label: I18n.get("K-Pop") },
    { value: 11, label: I18n.get("Latino") },
    { value: 12, label: I18n.get("Metal") },
    { value: 13, label: I18n.get("Pop") },
    { value: 14, label: I18n.get("R&B/Soul") },
    { value: 15, label: I18n.get("Reggae") },
    { value: 16, label: I18n.get("Rock") },
    { value: 999, label: I18n.get("Other") }
];

module.exports.adjectives = [
    { value: 0, label: I18n.get("None"), freq: "" },
    { value: 1, label: I18n.get("Air"), freq: "10-16kHz" },
    { value: 2, label: I18n.get("Big"), freq: "40-250Hz" },
    { value: 3, label: I18n.get("Body"), freq: "150-600Hz" },
    { value: 4, label: I18n.get("Boom"), freq: "60-250Hz" },
    { value: 5, label: I18n.get("Bright"), freq: "2-12kHz" },
    { value: 6, label: I18n.get("Brilliant"), freq: "5-16kHz" },
    { value: 7, label: I18n.get("Clarity"), freq: "2.5-5kHz" },
    { value: 8, label: I18n.get("Close"), freq: "2-4kHz" },
    { value: 9, label: I18n.get("Crispy"), freq: "5-10kHz" },
    { value: 10, label: I18n.get("Crunch"), freq: "200-800Hz or 1400-2800Hz" },
    { value: 11, label: I18n.get("Dark"), freq: "lack of 2-12kHz" },
    { value: 12, label: I18n.get("Definition"), freq: "2-6 kHz" },
    { value: 13, label: I18n.get("Distant"), freq: "Lack of 700-20000Hz" },
    { value: 14, label: I18n.get("Fat"), freq: "60-250Hz" },
    { value: 15, label: I18n.get("Heavy"), freq: "40-200Hz" },
    { value: 16, label: I18n.get("Honk"), freq: "400-3000Hz" },
    { value: 17, label: I18n.get("Impact"), freq: "62-400 Hz" },
    { value: 18, label: I18n.get("Intelligibility"), freq: "2-4kHz" },
    { value: 19, label: I18n.get("In your face"), freq: "1.5-6kHz" },
    { value: 20, label: I18n.get("Metallic"), freq: "5-8kHz" },
    { value: 21, label: I18n.get("Muffled"), freq: "lack of 800-5000Hz" },
    { value: 22, label: I18n.get("Nasal"), freq: "500-1200Hz" },
    { value: 23, label: I18n.get("Natural tone"), freq: "80-400Hz (Drum)" },
    { value: 24, label: I18n.get("Phone-like"), freq: "800-4000kHz" },
    { value: 25, label: I18n.get("Power"), freq: "40-200Hz" },
    { value: 26, label: I18n.get("Presence"), freq: "2-6kHz" },
    { value: 27, label: I18n.get("Punch"), freq: "40-200Hz" },
    { value: 28, label: I18n.get("Rumble"), freq: "40-200Hz" },
    { value: 29, label: I18n.get("Screaming"), freq: "5-12kHz" },
    { value: 30, label: I18n.get("Shrill"), freq: "5-8kHz" },
    { value: 31, label: I18n.get("Strident"), freq: "5-8kHz" },
    { value: 32, label: I18n.get("Sub-bass"), freq: "16-60Hz" },
    { value: 33, label: I18n.get("Sweet"), freq: "250-800Hz" },
    { value: 34, label: I18n.get("Thin"), freq: "lack of 40-250Hz" },
    { value: 35, label: I18n.get("Tone"), freq: "500-1000Hz" }
];

module.exports.instruments = [
    { value: 1, label: I18n.get("Kick (In)") },
    { value: 2, label: I18n.get("Kick (Out)") },
    { value: 3, label: I18n.get("Snare (Top)") },
    { value: 4, label: I18n.get("Snare (Bottom)") },
    { value: 5, label: I18n.get("High Tom") },
    { value: 6, label: I18n.get("Mid Tom") },
    { value: 7, label: I18n.get("Floor Tom") },
    { value: 8, label: I18n.get("Hi-Hat") },
    { value: 9, label: I18n.get("Ride") },
    { value: 10, label: I18n.get("Over (L)") },
    { value: 11, label: I18n.get("Over (R)") },
    { value: 12, label: I18n.get("Bass") },
    { value: 13, label: I18n.get("Guitar") },
    { value: 14, label: I18n.get("Guitar (L)") },
    { value: 15, label: I18n.get("Guitar (R)") },
    { value: 16, label: I18n.get("Lead Guitar") },
    { value: 17, label: I18n.get("Acoustic Guitar") },
    { value: 18, label: I18n.get("Keys (L)") },
    { value: 19, label: I18n.get("Keys (R)") },
    { value: 20, label: I18n.get("Synth (L)") },
    { value: 21, label: I18n.get("Synth (R)") },
    { value: 22, label: I18n.get("Keys") },
    { value: 23, label: I18n.get("Synth") },
    { value: 24, label: I18n.get("Lead Vocals") },
    { value: 25, label: I18n.get("Choir (L)") },
    { value: 26, label: I18n.get("Choir (R)") },
    { value: 27, label: I18n.get("Vocals") },
    { value: 28, label: I18n.get("Choir") },

    { value: 999, label: I18n.get("Other") }
];

module.exports.reviewQualifiers = {
  volume: [
    { value: 0, label: I18n.get("It's OK") },
    { value: 1, label: I18n.get("Greatly decrease volume") },
    { value: 2, label: I18n.get("Decrease volume") },
    { value: 3, label: I18n.get("Increase volume") },
    { value: 4, label: I18n.get("Greatly increase volume") },
  ],
  adjective: [
    { value: 0, label: I18n.get("It's OK") },
    { value: 1, label: I18n.get("Not enough") },
    { value: 3, label: I18n.get("Too much") }
  ],
  eq: [
    { value: 0, label: I18n.get("It's OK") },
    { value: 1, label: I18n.get("Make it brighter") },
    { value: 2, label: I18n.get("Make it darker") }
  ],
  effect: [
    { value: 0, label: I18n.get("It's OK") },
    { value: 1, label: I18n.get("More effect") },
    { value: 2, label: I18n.get("Less effect") },
    { value: 3, label: I18n.get("Remove effect") }

  ]
  ,
  waveEffect: [
    { value: 0, label: I18n.get("It's OK") },
    { value: 1, label: I18n.get("Add more effect") },
    { value: 2, label: I18n.get("Remove some effect") },
    { value: 3, label: I18n.get("Turn on effect") },
    { value: 4, label: I18n.get("Turn off effect") }
  ],
  waveVolume: [
    { value: 0, label: I18n.get("It's OK") },
    { value: 1, label: I18n.get("Greatly decrease volume") },
    { value: 2, label: I18n.get("Decrease volume") },
    { value: 3, label: I18n.get("Increase volume") },
    { value: 4, label: I18n.get("Greatly increase volume") },
    { value: 5, label: I18n.get("Add a fade-in") },
    { value: 6, label: I18n.get("Add a fade-out") },
  ]

};

module.exports.helperDescriptions = {
  volume: I18n.get("Here you can ask to the engineer to change the volume of the track in the mix."),
  adjective: I18n.get("Here you can ask to the engineer to give more relevance - or not - to the adjective provided by you to the track during the setup of the project."),
  eq: I18n.get("Here you can ask to the engineer to change the equalization (the balance between the high and low frequencies) of the track."),
  effect: I18n.get("Here you can ask to change the quantity of effect as reverb, delay, chorus - if present - added by the engineer")
};

module.exports.extras = [
    { value: 0, label: I18n.get("Vocal auto tune"), price: 1000 },
    { value: 1, label: I18n.get("Vocal manual tune"), price: 2000 }
    //{ value: 2, label: I18n.get("Analog master"), price: 3000 },
    //{ value: 3, label: I18n.get("Tape recording master"), price: 3000 }
];

module.exports.vatprice = function (price, vatType){
  let result = price;
  if (vatType !== "EUR_VAT" && vatType !== "EXTRA_VAT") {
    result = Math.round(price * 1.22); //Apply italian IVA
  }
  return (result/100).toFixed(2);
}
