import React, { Component } from 'react';

import { I18n } from 'aws-amplify';

/**
 * A component to search for songs from Spotify.
 * Will call "props.onReferenceSongsChanged" prop with full ref songs array on each change.
 *
 * props
 *      end: end timestamp
 *      onCountdownEnded: countdown ended callback
 *
 * */
export default class Countdown extends Component {

    constructor(props) {
        super(props);

        let time = null;
        let interval = null;

        if (this.props.end!== undefined) {
            if (this.props.end > Date.now()) {

                //Start recurrent action (1sec)
                interval = setInterval(() => {

                    //Check end
                    let timestamp = this.props.end - Date.now();

                    if (timestamp > 0) {
                        //Format time
                        var date = new Date(timestamp);
                        var hours = date.getHours() - 1;
                        var minutes = "0" + date.getMinutes();
                        var seconds = "0" + date.getSeconds();
                        var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

                        this.setState({
                            time: formattedTime
                        });
                    } else {
                        //Countdown ended
                        clearInterval(this.state.interval);
                        this.setState({
                            time: I18n.get("TIME ENDED"),
                            interval: null
                        });
                        this.props.onCountdownEnded();
                    }

                }, 1);
            }
            else {
                //Time already ended
                time = I18n.get("0:00:00");
            }
        }

        //Set state
        this.state = {
            time: time,
            interval: interval
        }
    }

    render() {
        return (
            <div>
                {this.state.time ?
                    <h3>{this.state.time}</h3>
                    : null}
            </div>
        );
    }

}
