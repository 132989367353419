import React, { Component } from "react";
import { Container, Row, Form, Button, Col, Alert } from "react-bootstrap";
import { Card } from "components/Card/Card";
import FiscalInfo from "components/FormInputs/FiscalInfo";
import { API, graphqlOperation, I18n, Auth } from 'aws-amplify';
import * as mutations from 'graphql/SILmutations';
import { euCountriesList} from "variables/Countries";

import TagManager from 'react-gtm-module';

export class Welcome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validated: false,
      role: "",
      password: undefined,
      authError: "",
      showAuthError: false,
      loading: false,
      submitText: "Submit"
    };
    this.Submit = this.Submit.bind(this)
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  async Submit(data) {
    let vatkind = "EXTRA_VAT";
    if (euCountriesList.includes(data.country) && "IT"!==data.country) {
      if (data.indcomp === "individual") {
        vatkind= "EUR_PRIVATE";
      }else if (data.vies===true) {
        vatkind= "EUR_VAT";
      }else{
        vatkind= "EUR_VAT_NO_VIES";
      }
    }else if ("IT"===data.country) {
      if (data.indcomp === "individual") {
        vatkind= "ITA_PRIVATE";
      }else if (data.forfe===true) {
        vatkind= "ITA_PIVA_FORFET";
      }else{
        vatkind= "ITA_PIVA";
      }
    }else if (data.indcomp === "individual") {
      vatkind = "EXTRA_PRIVATE";
    }

    let collectedInfo= {
      acceptedTermsVersion: 1,
      vatType: vatkind,
      address: data.address,
      city: data.city,
      postCode: data.postcode,
      country: data.country,
      vatNumber: data.taxcode
    };
    if (data.country==="IT") {
      collectedInfo["provincia"] = data.province;
      if (data.indcomp ==="company"){
      collectedInfo["codiceDestinatario"] = data.sdi;
      collectedInfo["PEC"] = data.pec;
      }
    }
    if (vatkind.includes("PRIVATE")) {
      collectedInfo["name"] = data.name;
      collectedInfo["surname"] = data.surname;
    }else {
      collectedInfo["companyName"] = data.fullname;
    }

    try {
      await API.graphql(graphqlOperation(mutations.SILjoinGroup, {"input": { "groupName": this.state.role, "userData": collectedInfo }}));
      TagManager.dataLayer({ dataLayer: {event: "sil_add_payment_info"} });
      this.props.onSelectedGroup(this.state.role);
    } catch (error) {

      this.setState(
        {
          showAuthError: true,
          validated: false,
          authError: (error.message || error.errors[0].message || "Error!"),
          loading: false,
          submitText: "Submit"
        });
        console.log('error joining a group', error);
      }
    };


  render() {
    return (
      <Container className="mt-5">
        <Row >
          <Col lg="2" />
          <Col>
            <Card title={I18n.get("Welcome to Silimuse!")}
              content={
                <div>
                  <Form noValidate validated={this.state.validated} onSubmit={(e)=>{e.preventDefault();}}>

                    <Form.Group controlId="valid01">
                      <Form.Label>{I18n.get("What are you going to use Silimuse for?")}</Form.Label>
                      <Form.Control as="select"
                        name="role"
                        value={this.state.role}
                        onChange={this.onChange}
                        required
                        custom>
                        <option disabled value="">{I18n.get("Please select...")}</option>
                        <option value="musicians">{I18n.get("MUSICIAN - Looking for mixes of my songs.")}</option>
                        <option value="engineers">{I18n.get("SOUND ENGINEER - Here to provide my services.")}</option>
                      </Form.Control>
                    </Form.Group>
                    <hr/>
                    </Form>
                <h4>Invoice Information</h4>
                <p className="category">We need some fiscal information that will only be used for the invoices you will receive.</p>
                <p className="required">All fields are required.</p>
                  <FiscalInfo
                    submitText="Submit"
                    onAnySubmit={()=>this.setState({validated:true})}
                    onValidSubmit={this.Submit}/>
                    <Alert variant="danger" show={this.state.showAuthError}>
                      {this.state.authError}
                    </Alert>
                </div>
              }
              />
            {/* END CARD */}
          </Col>
          <Col lg="2">
            {window.amplify_env === "dev" && <Button onClick={() => Auth.signOut()}>{I18n.get("Logout")}</Button>}
          </Col>
        </Row>

      </Container >
    );
  }
}

export default Welcome;
