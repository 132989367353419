/*!

Silimuse Beta

Filippo Carnovalini

*/
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';
import { I18n } from 'aws-amplify';
import { instruments, adjectives, reviewQualifiers, listFetch, helperDescriptions } from "variables/SiliVariables";
import { v4 as uuid } from 'uuid';

import { InputGroup, Form, Button, Spinner, Popover, OverlayTrigger } from "react-bootstrap";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';

const selectStyle = {
  menu: (provided, state) => ({
    ...provided,
    "z-index": 5
  }),

}

/**
* A component that shows review input 3rd level
*
* props.onDataUpdate - called when data gets updated by user
* props.readOnly - prevent changes to review forms
* props.song - the mp3 file for the song
* props.adjective - the special adjective for the track
*
* */

export class ReviewWave extends Component {
    constructor(props) {
        super(props)

        let oldvalues = {};
        if (this.props.value){
          this.props.value.forEach((item, i) => {
            oldvalues[i] = item
          });
        }
        this.state = {
            id: uuid(),
            selected: null,
            isPlaying: false,
            values: oldvalues
        }
        this.updateList = this.updateList.bind(this);
    }
    componentDidMount() {
        this.waveform = WaveSurfer.create({
            barWidth: 3,
            barRadius: 3,
            cursorWidth: 1,
            responsive: true,
            container: "#waveform"+this.state.id,
            height: 100,
            normalize: true,
            progressColor: 'rgb(136, 136, 136)',
            waveColor: 'rgb(136, 136, 136)',
            cursorColor: '#000',
            plugins: [
                RegionsPlugin.create({
                    maxRegions: 10,
                    dragSelection: !this.props.readOnly,
                    edgeScrollWidth: 50
                }),
                TimelinePlugin.create({
                    container: '#wave-timeline'+this.state.id,
                    height: 10,
                })
            ]
        });

        this.waveform.on('region-created', (region) => { region.drag=false; this.updateList(region);  });
        this.waveform.on('region-click', (region,e) => {  this.updateList(region); this.waveform.seekTo(this.waveform.drawer.handleEvent(e));});
        this.waveform.on('region-dblclick', (region) => { region.playLoop();});
        this.waveform.on('region-update-end', (region) => { this.updateList(region); });
        this.waveform.on('play', () => this.setState({ isPlaying: true }));
        this.waveform.on('pause', () => this.setState({ isPlaying: false }));
        this.waveform.on('ready', () => this.setState({ ready: true }))
        this.waveform.container.firstChild.style.overflow = "";
        this.waveform.load(this.props.song);

        if (this.props.value){
          this.props.value.forEach((item, i) => {
            let reg = this.waveform.addRegion({id: i, start:item.start, end: item.end})
            reg.adj= item.adjective;
            reg.volume= item.volume;
            reg.effect= item.effect;
            reg.drag= false;
            reg.resize= !this.props.readOnly;
          });
        }

        this.setState({wf : this.waveform});
    }

    updateList(region) {

      if (region != this.state.selected) {
            if (this.state.selected){
                this.state.selected.element.className = this.state.selected.element.className.replace(/\b\striangle\b/g, "");
                this.state.selected.update({ color: "rgba(0, 0, 0, 0.1)" });
              }
            region.element.className += " triangle";
            region.update({ color: "#6c054266" });

      }
      if (this.props.readOnly){
        this.setState({selected:region});
        return;
      }
      //else
      let vol = region.volume || 0;
      let a = region.adj || 0;
      let fx = region.effect || 0;
      this.setState({selected: region, values: {...this.state.values, [region.id]: {"volume":vol, "adjective":a, "effect":fx, "start": region.start, "end": region.end}}},this.props.onDataUpdate(Object.values(this.state.values)));
    }

    removeRegion(region) {
      if (this.props.readOnly){
        return;
      }
    const newValues = Object.keys(this.state.values)
      .filter(key => key!= region.id)
      .reduce((obj, key) => {
        obj[key] = this.state.values[key];
        return obj;
      }, {});
    this.setState({selected: null, values: newValues});
    this.props.onDataUpdate(Object.values(newValues));
    region.remove();
}

    static getDerivedStateFromProps(props, state){
      return {visible:props.currentlyVisible}
    }



    render() {

        if (this.state.visible && this.waveform){
          let wf = this.waveform;
          window.requestAnimationFrame(function() {
            //wavesurfer does not render if the wave was in a hidden block.
            // the following forces the rerender. requestAnimationFrame is needed
            // to ensure this is performed once the element is actually drawn.
            wf.drawBuffer();
        });
        }
        let regionBlock = "";

        function popover(title,element) {
          return (
            <Popover id="popover-basic">
              <Popover.Title as="h3">{title}</Popover.Title>
              <Popover.Content>
                {element}
              </Popover.Content>
            </Popover>
          );
        }

        if (this.state.selected != null) {
            let region = this.state.selected;
            regionBlock = <div id="selected-region" className="selectedRegionDescription" >
                <div className="controls">
                    <p>
                      Region time: <span className="text-monospace">{this.state.selected.start.toFixed(1)}s - {this.state.selected.end.toFixed(1)}s</span>

                    </p>
                    {!this.props.readOnly && <Button className="btn btn-inline btn-icon float-right" onClick={() => { this.removeRegion(region); }}>
                        <span className="fa fa-trash fa-1x" title="Delete Region" ></span></Button>
                      }

                </div>
                <h4>In this section, this track should be:</h4>
                <InputGroup>


                        <Col md={4}>
                          <p className="form-label">{I18n.get("Volume")}&nbsp;
                            <OverlayTrigger  placement="top" overlay={popover("Volume",helperDescriptions.volume)}>
                            <span className="fa fa-question-circle fa-1x" title="info" ></span>
                            </OverlayTrigger></p>
                          <Select
                            styles={selectStyle}
                            options={reviewQualifiers.waveVolume}
                            value={reviewQualifiers.waveVolume.find(op => op.value === this.state.selected.volume) || reviewQualifiers.waveVolume[0]}
                            onChange={(val) => {region.volume=val.value; this.updateList(region)}}
                            isDisabled={this.props.readOnly} />
                        </Col>


                        <Col md={{ span: 4, offset: 0 }}>
                          <p className="form-label">{I18n.get("Effect")}&nbsp;
                            <OverlayTrigger  placement="top" overlay={popover("Effect",helperDescriptions.effect)}>
                            <span className="fa fa-question-circle fa-1x" title="info" ></span>
                            </OverlayTrigger></p>
                          <Select
                            styles={selectStyle}
                            value={reviewQualifiers.waveEffect.find(op => op.value === this.state.selected.effect) || reviewQualifiers.waveEffect[0]}
                            options={reviewQualifiers.waveEffect}
                            onChange={(val) => {region.effect=val.value; this.updateList(region)}}
                            isDisabled={this.props.readOnly} />
                        </Col>


                        <Col md={{ span: 4, offset: 0 }}>
                          <p className="form-label">{this.props.adjective}&nbsp;
                            <OverlayTrigger  placement="top" overlay={popover("Adjective",helperDescriptions.adjective)}>
                            <span className="fa fa-question-circle fa-1x" title="info" ></span>
                            </OverlayTrigger></p>
                          <Select
                            styles={selectStyle}
                            value={reviewQualifiers.adjective.find(op => op.value === this.state.selected.adj) || reviewQualifiers.adjective[0]}
                            options={reviewQualifiers.adjective}
                            onChange={(val) => {region.adj=val.value; this.updateList(region)}}
                            isDisabled={this.props.readOnly} />
                        </Col>

                </InputGroup>


            </div>
        }

        return (
            <div className="waveReview">
                {!this.state.ready &&
                    <>{I18n.get("Loading... ")}<Spinner animation="border" /></>
                }
                {this.state.ready &&
                    <button type="button" className="btn btn-inline btn-icon btn-playpause" onClick={() => this.waveform.playPause()}>
                        <span className={this.state.isPlaying ? "fa fa-pause fa-1x" : "fa fa-play fa-1x"} title="play" ></span>
                    </button>
                }
                <div className="waveform">
                    <div id={"waveform"+this.state.id} />
                    <div id={"wave-timeline"+this.state.id} />
                </div>

                {regionBlock}

            </div>
        );
    }
}

export default ReviewWave;
