/*!

Silimuse Beta

Filippo Carnovalini
*/
import React, { Component } from "react";
import { I18n } from 'aws-amplify';
import {Modal,Button} from "react-bootstrap";

export class SilModal extends Component {
  /*
  Props:
  title - displays in header
  message - displays in Body
  actionMessage - text of main button
  callback - function to be called when main button is clicked
  */
  constructor(props){
    super(props)
    this.state={
      show:false
    }
  }

  show(){
    this.setState({show:true});
  }

  render() {
    return (
      <Modal show={this.state.show} onHide={()=>this.setState({show:false})}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-round" onClick={()=>this.setState({show:false})}>
            {I18n.get("Close")}
          </Button>
          <Button variant="primary btn-round" onClick={()=>{this.setState({show:false});this.props.callback()}}>
            {this.props.actionMessage}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SilModal;
