/*!

Silimuse Beta

Filippo Carnovalini

*/
import { API, Auth, graphqlOperation, I18n, Storage } from 'aws-amplify';
import awsconfig from 'aws-exports';
import * as mutations from 'graphql/SILmutations';
import React, { Component } from "react";
import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import { v4 as uuid } from 'uuid';
import { extras, adjectives, genres, instruments, freqFetch, listFetch, vatprice } from "variables/SiliVariables";
import Countdown from '../Countdown';

import {errorFetch} from "variables/SiliErrors";
import Review from '../FormInputs/Review';




export class JobPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase: 0,
            uploadMessage: I18n.get("Confirm and send Mix"),
            uploadErrorMessage: "",
            uploadError: false,
            ready: false,
            fname: "",
            file: "",
            errors: {
                fname: "",
            },
            ok: {
                fname: false,
            }
        };
        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.upload = this.upload.bind(this);
    }

    handleChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value });
        if (ev.target.name === "fname") {
            this.setState({ file: ev.target.files[0] });
        }
        const newerr = this.state.errors;
        const newok = this.state.ok;
        if (ev.target.value === "") {
            newerr[ev.target.name] = I18n.get("This field is required!");
            newok[ev.target.name] = false;
        } else {
            newerr[ev.target.name] = "";
            newok[ev.target.name] = true;
        }
        this.setState({ errors: newerr }, this.validate);
    }

    validate() {
        let res = true;
        let el;
        for (el in this.state.ok) {
            if (!this.state.ok[el]) {
                res = false;
            }
        }
        this.setState({ ready: res });
    }

    async upload() {
        this.setState({ ready: false, uploadMessage: I18n.get("Uploading... "), phase: 1 });
        const { identityId } = await Auth.currentCredentials();
        const bucket = awsconfig.aws_user_files_s3_bucket;
        const region = awsconfig.aws_user_files_s3_bucket_region;

        // Prepare data for mutation, which will be done later.

        let key = `private/${identityId}/${uuid()}Silimuse-Mix.${this.state.file.name.split('.').pop()}`;
        const uploadVars = {
            file: {
                bucket,
                key,
                region,
            }
        };

        try {    // Upload the mix
            const { type: mimeType } = this.state.file;
            // next line takes the name generated above using uuid + original file fileName,
            // is taken from the key for convenience.
            const fileName = uploadVars.file.key.split("/")[2];
            await Storage.put(fileName, this.state.file, {
                level: "private",
                contentType: mimeType
            });
            this.setState({ uploadMessage: I18n.get("Saving... ") });
            // Create Job mutation. If this fails there is garbage on the storage!
            await API.graphql(graphqlOperation(
                mutations.submitJobReview, { input: uploadVars, id: this.props.job.id }));
            this.setState({ uploadMessage: I18n.get("Done!"), phase: 2 })
        } catch (err) {
            // This is bad
            console.log('error: ', err);
            this.setState({ phase: 0, uploadError: true, uploadErrorMessage: errorFetch(err) });

        }
    }



    render() {
        let date = new Date(this.props.job.createDate);
        let month = date.getMonth();
        let day = date.getDate();
        let upload;
        if (this.props.job.status === "ASSIGNED") {
            upload = <div>
                <h4>{I18n.get("Upload Mix")}</h4>
                <Form>
                    <Form.File
                        label={this.state.fname === "" ? I18n.get("Choose audio file...") : this.state.file.name}
                        name="fname"
                        accept="audio/*"
                        onChange={(e) => this.handleChange(e)}
                        custom />
                    <span className="formError">{this.state.errors.fname}</span>
                </Form>
                <br />
                <Button disabled={!this.state.ready} active={!this.state.ready} onClick={this.upload}>
                    {this.state.uploadMessage}
                    {this.state.phase === 1 ? <Spinner as="span" animation="border" size="sm" /> : ""}</Button>
                {this.state.uploadError ? <Alert variant="danger">{this.state.uploadErrorMessage}</Alert> : ""}
            </div>
        }
        if (this.props.job.status === "REVIEW") {
            upload = <div>
                <h4>{I18n.get("Upload Mix")}</h4>
                <p>{I18n.get("You uploaded a mix that is being evaluated by the musician who issued the job. Please wait until the musician replies to your submission.")}</p>
            </div>
        }
        if (this.state.phase === 2) {
            upload = <div>
                <h4>{I18n.get("Upload Mix")}</h4>
                <Alert variant="success">{I18n.get("Your mix has been successfully uploaded!")}</Alert>
                <p>{I18n.get("You uploaded a mix that is being evaluated by the musician who issued the job. Please wait until the musician replies to your submission.")}</p>
            </div>
        }
        return (
            <div className="card">
                <div className="header">
                    <h4 className="title">{listFetch(genres, this.props.job.genre)} {I18n.get("Song")}</h4>
                </div>
                <div className="content">
                    <p>
                        {I18n.get("Date created:")} {day}/{month + 1}
                        <br />
                    </p>
                    {this.props.job.status==="ASSIGNED" && <Countdown end={this.props.job.timeoutDate} onCountdownEnded={() => { }} />}
                    <div className="reward">
                        <i className="fa fa-eur" /> {vatprice(this.props.job.prize,"EUR_VAT")} <br />
                    </div>
                    <p>
                      {I18n.get("Required Services:")}
                      </p>
                      <ul>
                        <li>{I18n.get("Mix & master")}</li>
                    {this.props.job.extras != null &&
                      this.props.job.extras.map((ex,i) =>
                        <li key={i}>{I18n.get(listFetch(extras, ex))}</li>
                      )
                    }
                  </ul>

                    <a download href={this.props.job.tracksZipFile.url}>{I18n.get("Download the tracks to work on")}</a>
                    <hr />
                    <h4>{I18n.get("Specifications")}</h4>
                    {this.props.job.tracklist.map((item, key) => {
                        return <Container fluid key={key} > <p>
                            {I18n.get("Track")} {item.number}: {listFetch(instruments, item.instrument)}
                            {item.adjectives[0] !== null & item.adjectives[0] !== "0" ? <> - {I18n.get("This track must sound:")} '{listFetch(adjectives, item.adjectives[0])}', {I18n.get("so you could focus on:")} {freqFetch(adjectives, item.adjectives[0])}</> : ""}</p></Container>
                    })}
                    <h5>{I18n.get("References for this song:")}</h5>
                    {this.props.job.references.map((el, i) => {
                        return (
                            <Container fluid key={i}>
                                <p>{el.referenceType === 0 ? I18n.get("Vocal reference:") : I18n.get("Instrumental reference:")}</p>
                                <iframe title={"chosen"} src={el.url} width="250" height="75" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            </Container>)
                    })}
                    {(this.props.job.reviews && this.props.job.reviews.slice(-1)[0].status === "REFUSED") &&
                        <>
                        <hr />
                        <h4>{I18n.get("Review")}</h4>
                        <Review job={this.props.job} readOnly={true} />
                        </>
                    }
                    <hr />
                    {upload}
                </div>
            </div>
        );
    }
}

export default JobPanel;
