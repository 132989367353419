import React, { Component, useContext } from "react";
import { Button, Row, Col, Accordion, Popover, OverlayTrigger } from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';
import {Spinner,Form ,ProgressBar } from "react-bootstrap";
import TagManager from 'react-gtm-module';

import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import utils from "audio-buffer-utils"

import { Auth, I18n } from 'aws-amplify';
import { Storage, API, graphqlOperation } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import * as mutations from 'graphql/SILmutations';

import * as queries from 'graphql/SILqueries';
import * as subscriptions from 'graphql/SILsubscriptions';
import { v4 as uuid } from 'uuid';
import awsconfig from 'aws-exports';

import Card from "components/Card/Card";
import SelectAudioFile from "./SelectAudioFile"
import TrackInstrument from "./TrackInstrument"

import {errorFetch} from "variables/SiliErrors";
import { genres, instruments, listFetch } from "variables/SiliVariables.jsx";

export default class TracksInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            isPlaying: false,
            piece: null,
            zoom: 5,
            index: 0,
            tracks: [],
            ready: false,
            allInstruments: false,
            soloOn: false,
            someMute: false,
            uploadMessage : "",
            phase: 0,
            progress: 0,
        }

    }

    componentDidMount() {
        this.ac = window.AudioContext || window.webkitAudioContext;
        this.ac = new AudioContext();
        if (localStorage.getItem('waiting')==="true"){
          let job_id = localStorage.getItem('job_id');
          this.restoreOldjob(job_id);
        }
      }

    async restoreOldjob(job_id){
      const result = await API.graphql({
        query: queries.getJob,
        variables: {id:job_id},
        authMode: 'API_KEY',
      });
      let job = result.data.getJob;
      if (job === null) {
        this.clearStorage();
        return;
      }
      if (job.status == "PROCESSING") {
        this.setState({phase: 3});
        console.log("On server");
        this.waitMix(job_id);
      }else if (job.status == "END") {
        console.log("Over");
        this.setState({phase: 4,finalMixUrl: job.finalMix.url});
        console.log(job.finalMix);
      }else if (job.status == "PENDING") {
        console.log("pending");
        this.setState({phase: 2});
        this.waitMix(job_id);
      }else {
        console.log("Error");
        this.setState({phase: 5});
        console.log(result);
      }
    }

    // componentDidUpdate() {
    //   localStorage.setItem('fullState', JSON.stringify(this.state));
    // }

    handleSubmit(event) {
      if (this.state.ready === false) {
        event.preventDefault();
        event.stopPropagation();
      }else{
        this.upload();
      }
      this.setState({validated:true});
    }

    async upload() {
      this.setState({ ready: false, uploadMessage: I18n.get("Uploading... Do not close or refresh this page!"), phase: 1 });
      //const { identityId } = await Auth.currentCredentials();
      const bucket = awsconfig.aws_user_files_s3_bucket;
      const region = awsconfig.aws_user_files_s3_bucket_region;

      // Prepare data for mutation, which will be done later.
      let tracklist = [];
      let counter = 0;
      this.state.tracks.forEach((tr, i) => {
        if (!tr.mute){ // ADD TRACK only if not muted
          let key = `public/raw/${uuid()}}`;
          console.log(key);
          tracklist.push({
            instrument: tr.instrument,
            startOffset: tr.shift,
            startRegion: tr.start,
            endRegion: tr.end,
            file: {
              bucket: bucket,
              key: key,
              region: region,
            }
          });
          counter +=1;
        }
      });
      const uploadVars = {
        input: {
          "tracklist": tracklist,
        }
      };
      try {
        // Upload each track
        counter = 0;
        for await (let [i, tr] of this.state.tracks.entries()) {
          if (!tr.mute){ // UPLOAD TRACK only if not muted
            this.setState({ uploadMessage: `${I18n.get("Uploading Track")} ${counter + 1}... Do not close or refresh this page!` });
            const { type: mimeType } = tr.file;
            // next line takes the name generated above using uuid + original file name,
            // is taken from the key for convenience.
            const fileName = "raw/"+tracklist[counter].file.key.split("/")[2];

            var self = this; // trick to use this in callback. Works but raises no-loop-func.
            await Storage.put(fileName, tr.file, {
              level: "public",
              contentType: mimeType,
              // eslint-disable-next-line no-loop-func
              progressCallback(prog) {
                let now = Math.floor((prog.loaded / prog.total * 100 / self.state.tracks.length) + (i*100 / self.state.tracks.length));
                self.setState({ progress: now })
              }
            });
            counter +=1;
          }
        }
        this.setState({ uploadMessage: I18n.get("Saving... ") });
        // Create Job mutation. If this fails there is garbage on the storage!
        let jobwascreated = false;
        while (!jobwascreated) {
          const result = await API.graphql({
            query: mutations.createJob,
            variables: uploadVars,
            authMode: 'API_KEY',
          });
          // const data = {
          //   'job_price' : result.data.createJob.price,
          //   'job_id'  : result.data.createJob.id,
          //   'event' : "sil_add_to_cart"
          // }
          // TagManager.dataLayer({ dataLayer: data });
          //console.log(result);
          if (result.data.createJob.status =="FAILED") {
            // the machine was closing when called.
            console.log(result.data);
            await new Promise(resolve => setTimeout(resolve, 15000)); // await 15 secs
          }else {
            jobwascreated = true;
            this.setState({ uploadMessage: "", phase: 2, uploadedID: result.data.createJob.id })
            this.waitMix(result.data.createJob.id);
          }
        }

      } catch (err) {
        // This is bad
        console.log('error: ', err);
        this.setState({ phase: 1000, uploadError: true, uploadErrorMessage: errorFetch(err) });
      }
    }

    async waitMix(job_id){

        localStorage.setItem('waiting', "true");
        localStorage.setItem('job_id', job_id);

        API.graphql({
          query: subscriptions.onUpdateJob,
          variables: {id:job_id},
          authMode: 'API_KEY',
        }).subscribe({
          next: res => {
            console.log("NEXT SUB");
            let job = res.value.data.onUpdateJob
            if (job.status == "PROCESSING") {
              this.setState({phase: 3});
              console.log("On server")
            }else if (job.status == "END") {
              console.log("Over")
              this.setState({phase: 4,finalMixUrl: job.finalMix.url});
              console.log(job.finalMix)
            }else {
              console.log("Error")
              this.setState({phase: 5});
              console.log(job)
            }
          },
          error: error => {
            console.log("ERROR SUB");
            this.setState({phase: 5});
            console.log(error);
          }
        });

    }

    // async tester(){
    //   try {
    //     const result = await API.graphql({
    //       query: mutations.createJob,
    //       authMode: 'API_KEY',
    //       variables: {input: {
    //
    //         "tracklist": [
    //           {"number": 1,
    //             "instrument": 10,
    //             "file": {
    //               "bucket": awsconfig.aws_user_files_s3_bucket,
    //               "key": "public/raw/3fdcf78b-c020-4ea5-9df3-63d88f78ff4b",
    //               "region": awsconfig.aws_user_files_s3_bucket_region,
    //             }
    //           }
    //       ],
    //       }},
    //
    //     });
    //     console.log(result);
    //   } catch (e) {
    //     console.log(e);
    //   }
    //
    // }




    updateInstrument(e, index){
      let tr = this.state.tracks;
      let r=true;
      tr.forEach((item, i) => {
        if (item.index == index){
          item.instrument=e.target.value;
        }
        if (item.instrument==null){
          r = false;
        }
      });
      this.setState({tracks:tr,allInstruments:r,ready:r});

    }

    restoreTracks(trs){
      trs.forEach((item, ind) => {
        this["waveform"+item.index] = WaveSurfer.create({

            cursorWidth: 1,
            responsive: true,
            container: "#waveform"+item.index,
            height: 100,
            minPxPerSec: this.state.zoom,
            scrollParent: true,
            progressColor: 'rgb(136, 136, 136)',
            waveColor: 'rgb(136, 136, 136)',
            cursorColor: '#000',
            plugins: [
                RegionsPlugin.create({
                  maxRegions: 1,
                  edgeScrollWidth: 50
                }),
                TimelinePlugin.create({
                  container: "#wave-timeline"
                })
            ]
        });
        console.log(item);
        console.log(item.buffer);
        let res2 = utils.padLeft(item.buffer, item.shift* item.buffer.sampleRate);
        res2 = utils.padRight(res2,this.state.maxlen * res2.sampleRate);
        this["waveform"+item.index].loadDecodedBuffer(res2);

        let reg = this["waveform"+ind].addRegion({start:item.start,end:item.end});
        let regwavetag= this["waveform"+ind].container.getElementsByTagName('wave')[0]

        reg.on("update" , (e)=>{
          //console.log(reg);

          // if(item.index!=ind && Math.abs(wavetag.scrollLeft-e.target.scrollLeft)>0.001){
          //   wavetag.scrollLeft= e.target.scrollLeft;
          // }
          this.pauseTracks();
          if (!reg.hasDragged && !reg.hasResized) {
            reg.oldstart= reg.start;
            reg.oldend= reg.end;
            regwavetag.querySelectorAll('canvas').forEach((item, i) => {
              item.oldleft = parseFloat(item.style.left);
            });
          }
          if (reg.isDragging) {
            regwavetag.querySelectorAll('canvas').forEach((item, i) => {
              //console.log(reg.start-reg.oldstart);
              item.style.left = String(item.oldleft+(reg.start-reg.oldstart)*this.state.zoom)+"px";
              //item.style.left="1000px";
              //item.style.opacity=0;
              //(reg.start-reg.oldstart)*100;
            });
          }
          reg.hasDragged = reg.isDragging;
          reg.hasResized = reg.isResizing;
        });
        reg.on("update-end" , (e)=>{
          //console.log(e)
          regwavetag.querySelectorAll('canvas').forEach((item, i) => {
            item.style.left = String(item.oldleft)+"px";
            //console.log(item.style.left);
            //item.style.left += reg.oldstart-reg.start;
            //item.style.opacity=1;
          });

          let seltrack = this.state.tracks.filter(obj => {
            return obj.index === ind;
          })
          if (reg.hasResized) {

            if (reg.start<=seltrack[0].shift) {
              reg.update({"start":seltrack[0].shift});
            }
            if (reg.end >seltrack[0].shift+seltrack[0].buffer.duration) {
              reg.update({"end":seltrack[0].shift+seltrack[0].buffer.duration});
            }

            let tr= this.state.tracks;
            tr.forEach((item, i) => {
              if (item.index==seltrack[0].index){
                item.start = reg.start;
                item.end = reg.end;
              }
            });
            this.setState({"tracks":tr})

            reg.hasResized=false;

          }
          if (reg.hasDragged) {
            //console.log(reg.start-reg.oldstart)
            let container = document.getElementsByClassName('waveform')[0];
            if (container == e.target || container.contains(e.target)) {
              let tr= this.state.tracks;
              let seekindex=0;
              tr.forEach((item, i) => {

                if (item.index==seltrack[0].index){
                  item.shift+=reg.start-reg.oldstart;
                  let resnew = utils.padLeft(item.buffer, (item.shift+item.buffer.duration)* item.buffer.sampleRate);
                  item.start = reg.start;
                  item.end = reg.end;

                  if (item.shift < 0) {
                    resnew = utils.slice(item.buffer, -(item.shift)* item.buffer.sampleRate);
                  }

                  resnew = utils.resize(resnew,this.state.maxlen * resnew.sampleRate);

                  //console.log(resnew);
                  //this["waveform"+item.index].empty()
                  this["waveform"+item.index].loadDecodedBuffer(resnew);
                  //this["waveform"+item.index].drawBuffer();


                }else{
                  seekindex = this["waveform"+item.index].backend.getPlayedPercents();
                  //console.log(seekindex);
                }
              });
              this["waveform"+seltrack[0].index].seekTo(seekindex);
              this.setState({"tracks":tr})
            }

            reg.hasDragged=false;
          }
          let container = document.getElementsByClassName('waveform')[0];

          if (container !== e.target && !container.contains(e.target)) {
            reg.update({"start":reg.oldstart,"end":reg.oldend});
          }


        });

        reg.on("out",(e)=>{
          this["waveform"+ind].setVolume(0);
        });
        reg.on("in",(e)=>{
          this["waveform"+ind].setVolume(1);
        });



        this["waveform"+ind].on('pause', () => {
          this.setState({isPlaying:false});
        });
        this["waveform"+ind].on('play', () => {
          this.setState({isPlaying:true});
        });

        this["waveform"+ind].on('seek', (time) => {
          trs.forEach((item, i) => {

            let perc = this["waveform"+item.index].backend.getPlayedPercents()
            if (item.index!=ind && Math.abs(time-perc)>0.000000001){
              this["waveform"+item.index].seekTo(time);
            }

          });
        });
        this["waveform"+ind].on('scroll', (e) => {
          trs.forEach((item, i) => {
            let wavetag= this["waveform"+item.index].container.getElementsByTagName('wave')[0]
            if(item.index!=ind && Math.abs(wavetag.scrollLeft-e.target.scrollLeft)>0.001){
              wavetag.scrollLeft= e.target.scrollLeft;
          }
          });
        });

        // //Seek everything to 0.
        // tr.forEach((item, i) => {
        //   this["waveform"+item.index].seekTo(0);
        //   let wavetag= this["waveform"+item.index].container.getElementsByTagName('wave')[0]
        //   if(item.index!=ind && wavetag.scrollLeft>0){
        //     wavetag.scrollLeft= 0;
        //   }
        // });

      });
    }

    selectTrack(f){
      f.arrayBuffer().then((arrayB)=>{
        this.ac.decodeAudioData(arrayB).then((res)=>{
          let tr = this.state.tracks;
          let ind = this.state.index+1;
          tr.push({"file":f, "buffer": res, "index":ind,"shift":0,"start":0,"end":res.duration, "instrument":null, solo:false, mute:false});
          let maxlen = 30;
          tr.forEach((el, i) => {
            if (el.buffer.duration> maxlen){
              maxlen = el.buffer.duration;
            }
          });
          this.setState({"maxlen":maxlen, "tracks": tr, "index":ind,allInstruments:false, ready:false})
          //this.waveform2.loadDecodedBuffer(res);
          this["waveform"+ind] = WaveSurfer.create({

              cursorWidth: 1,
              responsive: true,
              container: "#waveform"+ind,
              height: 100,
              minPxPerSec: this.state.zoom,
              scrollParent: true,
              progressColor: 'rgb(136, 136, 136)',
              waveColor: 'rgb(136, 136, 136)',
              cursorColor: '#000',
              plugins: [
                  RegionsPlugin.create({
                    maxRegions: 1,
                    edgeScrollWidth: 50
                  }),
                  TimelinePlugin.create({
                    container: "#wave-timeline"
                  })
              ]
          });

          // if(this["waveform"+ind].params.minPxPerSec!=this.state.zoom){
          //
          //   let px = this["waveform"+ind].params.minPxPerSec;
          //   this.setState({"zoom":px});
          //   console.log(this.state.zoom);
          // }

          tr.forEach((item, i) => {
            let res2 = utils.padLeft(item.buffer, item.shift* item.buffer.sampleRate);
            res2 = utils.padRight(res2,maxlen * res2.sampleRate);
            this["waveform"+item.index].loadDecodedBuffer(res2);
          });

          let reg = this["waveform"+ind].addRegion({start:0,end:res.duration});
          let regwavetag= this["waveform"+ind].container.getElementsByTagName('wave')[0]

          reg.on("update" , (e)=>{
            //console.log(reg);

            // if(item.index!=ind && Math.abs(wavetag.scrollLeft-e.target.scrollLeft)>0.001){
            //   wavetag.scrollLeft= e.target.scrollLeft;
            // }

            this.pauseTracks();

            if (!reg.hasDragged && !reg.hasResized) {
              reg.oldstart= reg.start;
              reg.oldend= reg.end;
              regwavetag.querySelectorAll('canvas').forEach((item, i) => {
                item.oldleft = parseFloat(item.style.left);
              });
            }
            if (reg.isDragging) {
              regwavetag.querySelectorAll('canvas').forEach((item, i) => {
                //console.log(reg.start-reg.oldstart);
                item.style.left = String(item.oldleft+(reg.start-reg.oldstart)*this.state.zoom)+"px";
                //item.style.left="1000px";
                //item.style.opacity=0;
                //(reg.start-reg.oldstart)*100;
              });
            }
            reg.hasDragged = reg.isDragging;
            reg.hasResized = reg.isResizing;
          });



          reg.on("update-end" , (e)=>{
            //console.log(e)
            regwavetag.querySelectorAll('canvas').forEach((item, i) => {
              item.style.left = String(item.oldleft)+"px";
              //console.log(item.style.left);
              //item.style.left += reg.oldstart-reg.start;
              //item.style.opacity=1;
            });

            let seltrack = this.state.tracks.filter(obj => {
              return obj.index === ind;
            })
            if (reg.hasResized) {

              if (reg.start<=seltrack[0].shift) {
                reg.update({"start":seltrack[0].shift});
              }
              if (reg.end >seltrack[0].shift+seltrack[0].buffer.duration) {
                reg.update({"end":seltrack[0].shift+seltrack[0].buffer.duration});
              }

              let tr= this.state.tracks;
              tr.forEach((item, i) => {
                if (item.index==seltrack[0].index){
                  item.start = reg.start;
                  item.end = reg.end;
                }
              });
              this.setState({"tracks":tr})

              reg.hasResized=false;

            }
            if (reg.hasDragged) {
              //console.log(reg.start-reg.oldstart)
              let container = document.getElementsByClassName('waveform')[0];
              if (container == e.target || container.contains(e.target)) {
                let tr= this.state.tracks;
                let seekindex=0;
                tr.forEach((item, i) => {

                  if (item.index==seltrack[0].index){
                    item.shift+=reg.start-reg.oldstart;
                    let resnew = utils.padLeft(item.buffer, (item.shift+item.buffer.duration)* item.buffer.sampleRate);
                    item.start = reg.start;
                    item.end = reg.end;

                    if (item.shift < 0) {
                      resnew = utils.slice(item.buffer, -(item.shift)* item.buffer.sampleRate);
                    }

                    resnew = utils.resize(resnew,this.state.maxlen * resnew.sampleRate);

                    //console.log(resnew);
                    //this["waveform"+item.index].empty()
                    this["waveform"+item.index].loadDecodedBuffer(resnew);
                    //this["waveform"+item.index].drawBuffer();


                  }else{
                    seekindex = this["waveform"+item.index].backend.getPlayedPercents();
                    //console.log(seekindex);
                  }
                });
                this["waveform"+seltrack[0].index].seekTo(seekindex);
                this.setState({"tracks":tr})
              }

              reg.hasDragged=false;
            }
            let container = document.getElementsByClassName('waveform')[0];

            if (container !== e.target && !container.contains(e.target)) {
              reg.update({"start":reg.oldstart,"end":reg.oldend});
            }


          });

          reg.on("out",(e)=>{
            this["waveform"+ind].setVolume(0);
          });
          reg.on("in",(e)=>{
            this["waveform"+ind].setVolume(1);
          });



          this["waveform"+ind].on('pause', () => {
            this.setState({isPlaying:false});
          });
          this["waveform"+ind].on('play', () => {
            this.setState({isPlaying:true});
          });

          this["waveform"+ind].on('seek', (time) => {
            tr.forEach((item, i) => {

              let perc = this["waveform"+item.index].backend.getPlayedPercents()
              if (item.index!=ind && Math.abs(time-perc)>0.000000001){
                this["waveform"+item.index].seekTo(time);
              }

            });
          });
          this["waveform"+ind].on('scroll', (e) => {
            tr.forEach((item, i) => {
              let wavetag= this["waveform"+item.index].container.getElementsByTagName('wave')[0]
              if(item.index!=ind && Math.abs(wavetag.scrollLeft-e.target.scrollLeft)>0.001){
                wavetag.scrollLeft= e.target.scrollLeft;
            }
            });
          });

          //Seek everything to 0.
          tr.forEach((item, i) => {
            this["waveform"+item.index].seekTo(0);
            let wavetag= this["waveform"+item.index].container.getElementsByTagName('wave')[0]
            if(item.index!=ind && wavetag.scrollLeft>0){
              wavetag.scrollLeft= 0;
            }
          });
        });
      });
    }
    async testsub(){
      this.waitMix("2b90efa9-ba2e-4419-b7b3-3b11dcbb2ce3");
    }
    // 2b90efa9-ba2e-4419-b7b3-3b11dcbb2ce3

    async testjob(){
      const result = await API.graphql({
        query: queries.getJob,
        variables: {id:"2b90efa9-ba2e-4419-b7b3-3b11dcbb2ce3"},
        authMode: 'API_KEY',
      });
      console.log(result);
    }

    zoomTracks(increase){
      let tr  = this.state.tracks;


      let z = this.state.zoom;
      if (increase){
        if (z <= 500){
          let newz = z *1.25;
          newz = newz<=500 ? newz : 500;
          tr.forEach((item, i) => {
            this["waveform"+item.index].zoom(newz);
          });
          this.setState({"zoom":newz});
        }


      }else{
        if (z >= 5){
          let newz = z *0.75;
          newz = newz>=5 ? newz : 5;
          tr.forEach((item, i) => {
            this["waveform"+item.index].zoom(newz);
          });
          this.setState({"zoom":newz});
        }
      }
    }
    playPauseTracks(){
      this.state.tracks.forEach((item, i) => {
        this["waveform"+item.index].playPause();
      });
    }
    pauseTracks(){
      this.state.tracks.forEach((item, i) => {
        this["waveform"+item.index].pause();
      });
    }
    stopTracks(){
      this.state.tracks.forEach((item, i) => {
        this["waveform"+item.index].pause();
        this["waveform"+item.index].seekTo(0);
        let wavetag= this["waveform"+item.index].container.getElementsByTagName('wave')[0]
        if(wavetag.scrollLeft>0){
          wavetag.scrollLeft= 0;
        }
      });
    }
    muteTrack(t){
      let trs = this.state.tracks;
      trs.forEach((item, i) => {
        if (item.index==t) {
          if (item.mute == false){
            item.mute = true;
            this["waveform"+t].setMute(true);
          }else{
            item.mute = false;
            if(!this.state.soloOn || item.solo){
              this["waveform"+t].setMute(false);
            }
          }
        }
      });
      let sM = false;
      trs.forEach((item, i) => {
        if (item.mute==true) {
          sM=true;
        }
      });
      this.setState({tracks:trs,someMute:sM});
    }
    soloTrack(t){
      let trs = this.state.tracks;
      let sOn = true
      trs.forEach((item, i) => {
        if (item.index==t) {
          if (item.solo == false){
            item.solo = true;
            item.mute = false;
            this["waveform"+t].setMute(false);
          }else{
            sOn = false;
            item.solo = false;
          }
        }
      });
      trs.forEach((item, i) => {
        if (item.index!=t) {
          if (item.solo == true){
            item.solo = false;
          }
          if (sOn){
            this["waveform"+item.index].setMute(true);
          }else{
            if(!item.mute){
              this["waveform"+item.index].setMute(false);
            }
          }

        }
      });
      let sM = false;
      trs.forEach((item, i) => {
        if (item.mute==true) {
          sM=true;
        }
      });
      this.setState({tracks:trs,soloOn:sOn,someMute:sM});

    }

    clearStorage(){
      localStorage.removeItem("waiting");
      localStorage.removeItem("job_id");
      //window.location.reload();
    }

    render() {
      let action = "";
      let cancel = "";
      if(this.state.tracks.length>0 && this.state.phase == 0){
        action =
        <>
        <h3>Step 2: Now you can trim and drag your tracks to align them.</h3>
        <button type="button" className="btn btn-inline btn-icon btn-playpause" onClick={() => {this.stopTracks()}}>
            <span className={"fa fa-fast-backward fa-1x"} title="stop" ></span>
        </button>
        <span > &nbsp; </span>
        <button type="button" className="btn btn-inline btn-icon btn-playpause" onClick={() => {this.playPauseTracks()}}>
            <span className={this.state.isPlaying ? "fa fa-pause fa-1x" : "fa fa-play fa-1x"} title="play" ></span>
        </button>
        <span className="float-right">
            <button type="button" className="btn" onClick={() => {this.zoomTracks(false)}}>
                Zoom Out
            </button>
            <span className=""> &nbsp; </span>
            <button type="button" className="btn" onClick={() => {this.zoomTracks(true)}}>
                Zoom In
            </button>
        </span>
        <div className="waveform">
        {this.state.tracks.map((el, i) => {
          return <div key={el.index} id={"waveform"+el.index} className="position-relative">
          <div className="TrackNumLabel">{i+1}</div>
          <div className={el.mute ? "TrackButton TrackMute on" : "TrackButton TrackMute"} onClick={(x)=>this.muteTrack(el.index)}>M</div>
          <div className={el.solo ? "TrackButton TrackSolo on" : "TrackButton TrackSolo"} onClick={(x)=>this.soloTrack(el.index)}>S</div>
        </div>

        })}
        <div id="wave-timeline"></div>
      </div>

      <h3>Step 3: Tell me the instruments you used:</h3>

        {this.state.tracks.map((el, i) => {
          return <div key={el.index} className="position-relative">
            <TrackInstrument track={el}  number={el.index} onUpdateTrack={(e,i)=>this.updateInstrument(e,i)} />
            <div className="TrackNumLabel">{i+1}</div>
          </div>
          //<div key={el.index} id={"waveform"+el.index} />

        })}

      <h3>Step 4: Upload and Mix!</h3>

      <div className="float-right text-right">
        <p className={this.state.ready ? "": "unableToUp"}>Is everything ready? Then &nbsp;
          <Button disabled={!this.state.ready}
            onClick={(e)=>this.handleSubmit(e)}
            >
            Upload
          </Button>
        </p>
      {this.state.allInstruments ? "": <p className="alert alert-warning">You still need to set the instruments for each track!</p>}
      {this.state.someMute ? <p className="alert alert-warning">There are some muted (M) tracks. Those will not be uploaded!</p> :""}
    </div>
      </>
      }

      let opening =<>
      <h3>Step 1: Select your Tracks!</h3>

        <Form.File
          className={""}
          label={(this.state.tracks.length==0) ? "Select some tracks to upload..." : "Add other tracks..."}
          accept="audio/*"
          name="fname"
          multiple
          onChange={(d)=>{Array.from(d.target.files).forEach((el) => {this.selectTrack(el)});
                    }}
          custom
          />
      </>;

    if (this.state.phase >0){
      opening = <>
      <h3>Sit back and wait for your mix!</h3>
      </>
    }
    if (this.state.phase>=4) {
      opening = "";

    }

    let postupload = "";
    if (this.state.phase == 2) {
      postupload = <>
      <h4>Your project is waiting in queue to start processing. Please wait!</h4>
      <Spinner as="span" animation="border" size="sm" />
      <p>You can come back later to download it (if you use the same browser), but remember that the mix will be deleted after 24 hours from its creation!</p>
      </>
    }
    if (this.state.phase == 3) {
      postupload = <>
      <h4>Your project is being mixed right now! This can take up to ten minutes.</h4>
      <Spinner as="span" animation="border" size="sm" />
      <p>You can come back later to download it (if you use the same browser), but remember that the mix will be deleted after 24 hours from its creation!</p>
      </>

    }
    if (this.state.phase == 4) {
      postupload = <>
      <h3>Here is your mix!</h3>
      <a className="Button" href={this.state.finalMixUrl}> {I18n.get("Download Awesome Mix!")}</a>
      <p>You can come back later to download it (if you use the same browser), but remember that the mix will be deleted after 24 hours from its creation!</p>
    <p>Remember, this is a public beta. If you have any feedback on the system, please let us know!</p>
      </>

      cancel = <>
      <a onClick={()=>this.clearStorage()} href="#">Go back to the track selection interface and start a new mix. You will lose access to the current mix!</a>
      </>

    }
    if (this.state.phase == 5) {
      postupload = <>
      <h3>This is embarassing...</h3>
      <p>Something went wrong with the mix. Try again!</p>
      </>

    }

    if (this.state.phase > 1 && this.state.phase <=3) {
      cancel = <>
      <a onClick={()=>this.clearStorage()} href="#">Cancel the current mix and go back to the track selection interface. You won't be able to undo this!</a>
      </>
    }
    if (this.state.phase == 5) {
      cancel = <>
      <a onClick={()=>this.clearStorage()} href="#">Go back to the track selection interface</a>
      </>

    }
    if (this.state.phase == 1000) {
      return (
        <Card
          content={
          <>
          <h3>Something went wrong. Please try again in five minutes.</h3>
          </>}
        />
      );

    }


      return (
        <Card
          content={
          <>
              {opening}

              {/*<!--SelectAudioFile onUpdateTrack={ (d)=>this.selectTrack(d.target.files[0]) }  /-->*/}

              {action}
              <p>{this.state.uploadMessage}</p>
              {this.state.phase === 1 ? <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} /> : ""}

              {postupload}
              {cancel}

              {/*<Button onClick={()=>this.testsub()}>Waitsub</Button>
            <Button onClick={()=>this.testjob()}>GetJob</Button>*/}


          </>}
        />
    );
  }

}
