import React,{Component} from 'react';

import App from "./App";

class AppWrapper extends Component {

  constructor(props) {
      super(props);
      this._validAuthStates =["signedIn"];
    }


  render() {
    // if(!this._validAuthStates.includes(this.props.authState)){
    //   return null;
    // }

    return <App user={{username: "Anon"}}/>
  }
}


export default AppWrapper;
