/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { API, graphqlOperation } from 'aws-amplify';

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import UserProfile from "views/UserProfile";
import EngineerNotAuthorized from 'views/EngineerNotAuthorized';
import NotFound from 'components/NotFound';
import { style } from "variables/Variables.jsx";

import routes from "routes-eng.js";
import * as queries from 'graphql/SILqueries';

import image from "assets/img/sidebar/mixer_blue.png";

class EngLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open"
    };

    this.checkStatus = this.checkStatus.bind(this);
  }

  async checkStatus(){
    try {
        const res = await API.graphql(graphqlOperation(queries.engineerCanAssign, { username: this.props.userID }));
        this.setState({ checked: true, status: res.data.getEngineerData.status, vatType: res.data.getEngineerData.userData.vatType });
    } catch (err) {
        // This is bad
        console.log('error: ', err);

    }
  }


  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/eng") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component
                {...this.props}
                vatType = {this.state.vatType}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Account";
  };
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    //var _notificationSystem = this.refs.notificationSystem;
    this.checkStatus();

  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    if (!this.state.checked) {
      return <div />
    }
    let rot = [{
        path: "/available",
        name: "Welcome",
        icon: "pe-7s-musiclist",
        component: EngineerNotAuthorized,
        layout: "/eng"
      }]
    if (this.state.status!=="PENDING"){
      rot = routes
    }
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} routes={rot} image={this.state.image}
        color={this.state.color}
        hasImage={this.state.hasImage}/>
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            layout="/eng"
            brandText={this.getBrandText(this.props.location.pathname)}
          />
        <Switch>
          {this.getRoutes(rot)}
          <Route
            path= "/eng/user"
            render={()=>{return (
              <UserProfile
                {...this.props}
                vatType = {this.state.vatType}
                handleClick={this.handleNotificationClick}
              />
            )}}
          />
          <Route component={NotFound} />
        </Switch>
          <Footer role="eng"/>
        </div>
      </div>
    );
  }
}

export default EngLayout;
