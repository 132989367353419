/*!

Silimuse beta

Filippo Carnovalini

*/
import React, { Component } from "react";
import { Container} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import JobCard from "components/Card/JobCard.jsx";

import { API, graphqlOperation, I18n } from 'aws-amplify';
import { Connect } from "aws-amplify-react";
import * as queries from 'graphql/SILqueries';
import { Alert, Spinner } from "react-bootstrap";
import Countdown from "components/Countdown";

import {errorFetch} from "variables/SiliErrors";

class AvailableJobs extends Component {
  constructor(props){
    super(props);
    this.state={
      checked:false
    }
  }
  async checkReady(){
    try {
        const res = await API.graphql(graphqlOperation(queries.engineerCanAssign, { username: this.props.userID }));
        this.setState({ checked: true, freeTime: res.data.getEngineerData.jobAssignLockedUntil });
    } catch (err) {
        // This is bad
        console.log('error: ', err);
        this.setState({ result: <Alert variant="danger">{I18n.get("Failed to obtain engineer information.")}</Alert> })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
      let content = <Connect query={graphqlOperation(queries.SILlistJobs)}>
          {({ data: { jobsByUnassigned }, loading, errors }) => {
              if (errors.length !== 0) { console.log(errors); return (
                <Card
                    title={I18n.get("Error Loading Data")}
                    content={<p>{errorFetch(errors[0])}</p>}
                    />) }
              if (loading || !jobsByUnassigned) return(
                <Card
                    title={<>{I18n.get("Loading...")}<Spinner animation="border" /></>} />
                );
              if (jobsByUnassigned.items.length === 0) return (
                <Card
                    title={I18n.get("No jobs available! Come back and check when new jobs are available.")} />
                );
              return (<div>{jobsByUnassigned.items.map((item, key) => {
                  return <JobCard vatType={this.props.vatType} action="accept" key={key} job={item} />
              })} </div>);
          }}
      </Connect>

      if (this.props.userID === null || !this.state.checked) {
          content = <Card
              title={<>{I18n.get("Loading...")}<Spinner animation="border" /> </>}
              />;
          this.checkReady();
      }else if (this.state.freeTime > Date.now()) {
        content = <Card
            title={<>{I18n.get("You cannot accept a new job right now")} </>}
            content={<>
              <p>You recently accepted a job, and you must stil wait <Countdown end={this.state.freeTime} /> before accepting a new job.</p>
              </>
            }
            />;
      }

      return (
        <div className="content">
            <Container fluid>
              <h2><span className="numberDot active">&nbsp;</span>{I18n.get("Available Jobs")}</h2>

              {content}
            </Container>
        </div>
      );
  }
}

export default AvailableJobs;
