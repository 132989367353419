import React,{Component} from 'react';
import './App.css';

import { Auth } from 'aws-amplify';

import {HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import MusLayout from "layouts/MusLayout.jsx";
import EngLayout from "layouts/EngLayout.jsx";
import Welcome from "components/Auth/Welcome";
import NotFound from 'components/NotFound';

class App extends Component {

  constructor(props) {
      super(props);

      this.groupCheck = this.groupCheck.bind(this);
      this.state = {
        role: null,
        username: this.props.user.username,
        showSetRole: false
      }
      this.groupCheck(false);

    }

  async groupCheck(bypass) {
    try {
      const user = await Auth.currentAuthenticatedUser({bypassCache: bypass});
      let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups === undefined){
        this.setState({showSetRole: 'true'});
        return;
      }
      if (groups.indexOf("engineers")!==-1){
        this.setState({role: 'engineer',showSetRole: 'false'});
      }else if (groups.indexOf("musicians")!==-1) {
        this.setState({role: 'musician',showSetRole: 'false'});
      }else{
        this.setState({showSetRole: 'true'});
      };

    } catch (e) {
      console.log(e);
      Auth.signOut();
    }


  }

  render() {
    var result;

    // if(this.state.role==="engineer"){
    //     result = (
    //       <div className="App Engineer">
    //         <Router>
    //         <Switch>
    //               <Route path="/eng" render={props => <EngLayout userID={this.state.username} {...props} role="engineer" />} />
    //               <Redirect from="/" to="/eng/available" />
    //            <Route component={NotFound} />
    //         </Switch>
    //       </Router>
    //       </div>
    //     );
    //   } else if (this.state.role==="musician"){
    //     result = (
    //       <div className="App Musician">
    //         <Router>
    //         <Switch>
    //             <Route path="/mus" render={props => <MusLayout userID={this.state.username}  {...props} role="musician" />} />
    //             <Redirect from="/" to="/mus/start" />
    //             <Route component={NotFound} />
    //         </Switch>
    //       </Router>
    //       </div>
    //     );
    //   } else if(this.state.showSetRole){
    //     result = (
    //       <Welcome onSelectedGroup={()=>this.groupCheck(true)}/>
    //     )
    //   }else{
    //     result=null;
    //   }
    result = (
        <div className="App Musician">
          <Router>
          <Switch>
              <Route path="/mus" render={props => <MusLayout userID={this.state.username}  {...props} role="musician" />} />
              <Redirect from="/" to="/mus/start" />
              <Route component={NotFound} />
          </Switch>
        </Router>
        </div>
      );
    return result
  }
}


export default App;
