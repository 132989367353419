/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { I18n } from 'aws-amplify';

class Footer extends Component {
    render() {
        var phrase = I18n.get("Silimuse, from musicians, for musicians.");
        if (this.props.role === "eng") {
            phrase = I18n.get("Silimuse, from engineers, for engineers.");
        }
        return (
            <footer className="footer">
                <Container fluid>
                    {/*
                      <nav className="pull-left">
                        <ul>

                            <li>
                                <a href="https://www.silimuse.com/terms_of_service" target="_blank"  rel="noopener noreferrer">Terms of Service</a>
                            </li>
                            <li>
                                <a href="https://www.iubenda.com/privacy-policy/43644172" target="_blank"  rel="noopener noreferrer">Privacy policy</a>
                            </li>
                            <li>
                                <a href="https://www.iubenda.com/privacy-policy/43644172/cookie-policy" target="_blank"  rel="noopener noreferrer">Cookie policy</a>
                            </li>
                        </ul>
                    </nav>
                  */}
                <p className="copyright pull-left">Contact: <a href="mailto:info@silimuse.com">info@silimuse.com</a></p>
                    <p className="copyright pull-right">
                        &copy; {new Date().getFullYear()}{" "} {phrase}
                    </p>
                </Container>
            </footer>
        );
    }
}

export default Footer;
