import { I18n } from 'aws-amplify';

//Clear previously added translations (from aws modules)
I18n._dict = {};

const dict = {
    'it': {
		"Classical": "Classica",
		"Tax code:": "Codice fiscale:",
		"VAT code:": "Partita IVA:",
    }
};

I18n.putVocabularies(dict);

//Prevent further vocabulary entries (from aws modules)
I18n.putVocabularies = function() {}