const { I18n } = require('aws-amplify');

const errors = [
    { value: "UNKNOWN", label: I18n.get("An error occurred, please try again later.") },
    { value: "GENERIC", label: I18n.get("An error occurred, please try again later.") },

    { value: "FILE_TOO_BIG", label: I18n.get("Uploaded file is too big (max is 100MB).") },
    { value: "AUDIO_FILE_INVALID", label: I18n.get("Audio file is invalid.") },
    { value: "AUDIO_NOT_HQ", label: I18n.get("Audio file is not high quality lossless (wav, alac, flac).") },
    { value: "AUDIO_INVALID_SAMPLE_RATE", label: I18n.get("Audio sample rate is not valid (sample rate must >= 8000 and <= 96000).") },
    { value: "AUDIO_INVALID_CHANNEL_N", label: I18n.get("Audio channel number is not valid (channels must be 1 or 2).") },
    { value: "AUDIO_INVALID_BIT_DEPTH", label: I18n.get("Audio bit depth is not valid (must be one of 8, 16, 24, 32).") },

    { value: "JOB_REVIEW_REQUIRES_VOTE", label: I18n.get("You must vote engineer's work before accepting it.") },
    { value: "JOB_NOT_ENOUGH_TRACKS", label: I18n.get("You must upload at least one track.") },
    { value: "JOB_TIER_MAX_CHANNELS_EXCEEDED", label: I18n.get("Your tier does not allow given number of track channels.") },

	{ value: "NOT_ALLOWED", label: I18n.get("You are not allowed to perform this operation.") },

	{ value: "PROMO_INVALID", label: I18n.get("Given promo code is invalid.") },

    { value: "ENGINEER_TIME_LOCKED", label: I18n.get("You must wait latest assigned job's available time to expire.") },
    { value: "ENGINEER_BANNED", label: I18n.get("You are banned.") },
    { value: "ENGINEER_PENDING", label: I18n.get("You must complete welcome jobs before using the platform.") },

    { value: "USER_MISSING_VAT_DATA", label: I18n.get("You have to fill all required vat informations.") },
    { value: "USER_NO_VIES", label: I18n.get("You are not registered to VIES.") }
	
];


module.exports.errorFetch = function (err) {
    let mess = err.message || err.errors[0].message;
    let entry = errors.find(function (el) {
        return el.value === mess;
    })
    if (entry === undefined) {
      entry = {value: "UNKNOWN", label: I18n.get("An error occurred, please try again later.")};
    }
    return entry.label
}
